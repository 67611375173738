import { Box } from "@mui/material";
import Animation from "components/Atoms/SharedComponent/Animation";
import { useRef } from "react";

export default function CardContent({ children, sx, targetElementRef }) {
  return (
    <>
      <Animation targetElementRef={targetElementRef} className={"move"} />
      <Box
        sx={{
          backgroundColor: "rgba(0, 0, 0, 0.22)",
          borderRadius: "8px",
          width: { xs: "100%", md: "200%" },
          mt: { xs: 0, md: -5, xl: -30 },
          mr: { xl: -150, lg: -120, md: -100 },
          py: { xs: 15, md: 0 },
          pb: { md: 15 },
          ...sx,
        }}
        ref={targetElementRef}
        className="moving-from-left"
      >
        <Box
          sx={{
            width: { xl: "65%", lg: "63%", md: "68%" },
            px: 15,
            mt: { xs: 0, md: 15 },
          }}
        >
          {children}
        </Box>
      </Box>
    </>
  );
}
