import React, { useState } from "react";
import AppRoute from "./common/Route";
import { ThemeProvider } from "@mui/material";
import { theme } from "./common/styles/theme";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { I18nextProvider } from "react-i18next";
import i18n from "common/i18n";
import "./App.css";
import { store } from "services/store";
import { Provider as ReduxProvider } from "react-redux";
import { ScrollToTop } from "components/Atoms/ScrollToTop/ScrollToTop";

const App = () => {
  const custom_theme = theme;
  const [isScrolled, setIsScrolled] = useState(false)
  console.log(isScrolled);
  return (
    <I18nextProvider i18n={i18n}>
      <ThemeProvider theme={custom_theme}>
        <ReduxProvider store={store}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <ScrollToTop />
            <AppRoute />
          </LocalizationProvider>
        </ReduxProvider>
      </ThemeProvider>
    </I18nextProvider>
  );
};
export default App;
