import { useEffect } from "react";
import HomeTemplate from "templates/home/Home.Template";
import { useDispatch, useSelector } from "react-redux";
import { homePageDataRequest } from "services/modules/home";

const HomePage = ({ setLayoutData }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const dispatch = useDispatch();

  const { homePageData } = useSelector((state) => state.homePage);
  useEffect(() => {
    const requestData = {
      body: { jsonrpc: "2.0", params: {} },
    };
    dispatch(homePageDataRequest({ ...requestData }));
  }, []);



  return <HomeTemplate homePageData={homePageData && homePageData} />;
};

export default HomePage;
