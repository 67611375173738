import Types from "./Types";

export const NewsDataSuccess = (payload) => ({
  type: Types.NEWS_PAGE_DATA_SUCCESS,
  payload,
});

export const NewsDataLoading = (payload) => ({
  type: Types.NEWS_PAGE_DATA_LOADING,
  payload,
});

export const NewsDetailsDataSuccess = (payload) => ({
  type: Types.NEWS_DETAILS_PAGE_DATA_SUCCESS,
  payload,
});
