import { Box, Typography } from '@mui/material';

export default function CompanyInfoGridItem({ data }) {
   const { comment, image } = data;

   const infoStyle = {
      padding: 20,
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      position: 'relative',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'end',
      zIndex: 2,
      '&:after': {
         content: '" "',
         display: 'block',
         position: 'absolute',
         backgroundImage:
            ' linear-gradient(180deg, rgba(27, 40, 83, 0.00) 0%, rgba(25, 31, 65, 0.50) 68.22%, #191F41 100%)',
         top: 0,
         right: 0,
         bottom: 0,
         left: 0,
         opacity: 0.55,
         width: '100%',
         zIndex: -999,
      },
      '&:nth-of-type(1)': {
         gridArea: { xs: '1 / 1 / 2 / 1', md: '1 / 1 / 7 / 2' },
      },
      '&:nth-of-type(2)': {
         gridArea: { xs: '2 / 1 / 3 / 1', md: '1 / 2 / 4 / 3' },
      },
      '&:nth-of-type(3)': {
         gridArea: { xs: '3 / 1 / 4 / 1', md: '4 / 2 / 7 / 3' },
      },
      '&:nth-of-type(4)': {
         gridArea: { xs: '4 / 1 / 5 / 1', md: '1 / 3 / 5 / 4' },
      },
      '&:nth-of-type(5)': {
         gridArea: { xs: '5 / 1 / 6 / 1', md: '5 / 3 / 7 / 4' },
      },
      '&:nth-of-type(6)': {
         gridArea: { xs: '6 / 1 / 7 / 1', md: '1 / 4 / 3 / 5' },
      },
      '&:nth-of-type(7)': {
         gridArea: { xs: '7 / 1 / 8 /1', md: '3 / 4 / 7 / 5' },
      },
   };
   return (
      <Box
         sx={{
            ...infoStyle,
            backgroundImage: `url(${image})`,
         }}
      >
         <Typography
            color="white"
            sx={{
               fontWeight: '600',
               fontSize: '23px',
               textAlign: 'center',
            }}
         >
            {comment}
         </Typography>
      </Box>
   );
}
