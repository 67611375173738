import { Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

export default function NewsCardData({ data, isMain, isActive }) {
  const navigate = useNavigate();
  return (
    <>
      <Typography
        variant="h5"
        fontWeight="bold"
        color={"#202249"}
        fontSize={{ xl:isActive? "30px": "25px", md: "20px", xs: "14px" }}
        sx={{ mb: "1rem", direction: "ltr !important" }}
      >
        {data?.title}
      </Typography>
      <Typography
        fontSize={{ xl: "22px", md: "18px", xs: "12px" }}
        sx={{ mb: "1rem", direction: "ltr !important" }}
      >
        {data?.date}
      </Typography>
      <Typography
        fontSize={{ md: isMain ? "35px" : "18px", xs: "12px" }}
        lineHeight={isMain ? "40px" : "30px"}
        letterSpacing="0.2px"
        fontWeight="400"
        component="span"
        width="100%"
        fontFamily="inter"
        sx={{
          wordBreak: "break-word",
          overflowWrap: "break-word",
          direction: "rtl",
          fontFamily: "monospace"
        }}
      >
        {data?.description?.length > 200
          ? data?.description?.slice(0, 200)
          : data?.description}
        {data?.description?.length > 200 && (
          <Typography
            fontSize={{ md: isMain ? "25px" : "18px", xs: "12px" }}
            lineHeight="30px"
            letterSpacing="0.2px"
            component="span"
            fontWeight="600"
            width="100%"
            sx={{
              wordBreak: "break-word",
              overflowWrap: "break-word",
              cursor: "pointer",
              fontFamily: "monospace"
            }}
            onClick={(_) => navigate(`/news-details/${data?.id}`)}
          >
            {" "}
            ..قرأة المزيد
          </Typography>
        )}
      </Typography>
    </>
  );
}
