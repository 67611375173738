import { Box } from "@mui/material";
import NavLinks from "./NavLinks";
import { useThemePalette } from "common/hooks/theme_palette";
export default function NavMenu({ navItems,brandsPageData }) {
  const { darkBlue } = useThemePalette();

  return (
    <Box
      sx={{
        display: { xs: "none", md: "flex" },
      //   gap: { md: 5, lg: 15 },
        backgroundColor: darkBlue,
        py:5
      }}
    >
      <NavLinks brandsPageData={brandsPageData} navItems={navItems} />
    </Box>
  );
}
