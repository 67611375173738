import { combineReducers } from "redux";
import modelReducer from "./modules/models/Reducer";
import homePageReducer from "./modules/home/Reducer";
import aboutPageReducer from "./modules/about/Reducer";
import careerPageReducer from "./modules/career/Reducer";
import contactUsPageReducer from "./modules/contactUs/Reducer";
import brandsPageReducer from "./modules/brands/Reducer";
import contactUsFormReducer from "./modules/Forms/Reducer";
import filesUploadReducer from "./modules/uploadFiles/Reducer";
import newsPageReducer from "./modules/news/Reducer";

const rootReducer = combineReducers({
  model: modelReducer,
  homePage: homePageReducer,
  aboutPage: aboutPageReducer,
  careerPage: careerPageReducer,
  contactUs: contactUsPageReducer,
  brandsPage: brandsPageReducer,
  forms: contactUsFormReducer,
  uploadFile: filesUploadReducer,
  newsPage: newsPageReducer
});

export default rootReducer;
