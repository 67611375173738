import { Button, Box, Typography } from "@mui/material";
import UploadFileIcon from "@mui/icons-material/UploadFile";

export default function UploadButton({ submit }) {
  return (
    <Box
      sx={{ display: "flex", flexDirection: { md: "column", xs:'row' }, alignItems: "center" }}
    >
      <UploadFileIcon
        sx={{ fontSize: { md: '24px', xs: '18px' } }}
      />
      <Button sx={{ color: "white", textTransform: "none", fontSize: { md: "18px", sm: '16px', xs: '11px' } }} onClick={submit}>
        Upload Resume
      </Button>
      {/* <Typography>{fileName}</Typography> */}
    </Box>
  );
}
