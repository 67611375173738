import Types from "./Types";

export const formLoading = (payload) => ({
  type: Types.FORM_LOADING,
  payload,
});

export const formFormSuccess = (payload) => ({
  type: Types.CONTACT_US_FORM_SUCCESS,
  payload,
});

export const careersFormSuccess = (payload) => ({
  type: Types.CAREERS_FORM_SUCCESS,
  payload,
});

export const newsLetterFormSuccess = (payload) => ({
  type: Types.NEWS_LETTER_FORM_SUCCESS,
  payload,
});

export const newsLetterFormFail = (payload) => ({
  type: Types.FAIL_NEWSLETER,
  payload,
});
