import { About, HomeHeader } from "components/Organisms/Home";
import LatestNews from "components/Organisms/Home/LatestNews";
import OurBusiness from "components/Organisms/Home/OurBusiness";
import { brands } from "data";

function HomeTemplate({ homePageData }) {

  return (
    <>
      {homePageData?.header && (
        <HomeHeader
          homePageData={homePageData}
          headerData={homePageData?.header}
        />
      )}

      <About homePageData={homePageData} />
      {homePageData?.brand_cards && (
        <OurBusiness brandsData={homePageData?.brand_cards} brands={brands} />
      )}
      {homePageData?.news && <LatestNews newsData={homePageData?.news} />}
    </>
  );
}

export default HomeTemplate;
