import { Grid } from '@mui/material';

import businessman from '../../../assets/images/home/about/close-up-businessman-with-digital-tablet.png';
import meeting from '../../../assets/images/home/about/meeting.png';
import strategy from '../../../assets/images/home/about/business-strategy-success-target-goals.png';
import CustomButton from 'components/Atoms/Buttons/CustomButton';
import { useNavigate } from 'react-router-dom';

export default function About({ aboutData }) {
   const imagesStyle = {
      borderRadius: '9px',
      boxShadow: '10px -10px 0px #fff',
   };
   // console.log(aboutData,'aboutData');
   return (
      <Grid container gap={{ lg: 15 }} justifyContent={{ xs: 'space-between' }}>
         <Grid item sx={{ mb: 10 }} xs={5.4} md={5}>
            <img
               loading="lazy"
               src={aboutData?.main_home_image[0]?.image_url}
               width="100%"
               height="100%"
               style={imagesStyle}
               alt="close-up-businessman-with-digital-tablet"
            />
         </Grid>
         <Grid
            item
            sx={{
               display: 'flex',
               flexDirection: 'column',
               gap: 25,
            }}
            xs={5.4}
            md={5}
         >
            <img src={aboutData?.first_sub_home_image[0]?.image_url} width="100%" style={imagesStyle} alt="meeting" loading="lazy" />
            <img
               src={aboutData?.second_sub_home_image[0]?.image_url}
               loading="lazy"
               width="100%"
               style={imagesStyle}
               alt="business-strategy-success-target-goals"
            />
         </Grid>
      </Grid >
   );
}
