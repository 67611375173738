import GetInTouch from "components/Organisms/Contact/GetInTouch";
import { images } from "assets/AssetHelper";
import PagesHeader from "components/Molecules/PagesHeader";
export default function Contact() {
  return (
    <>
      <PagesHeader
        img={images?.contactHeadImg}
        title="CONTACT US"
        description="Have any question ? We ‘d love to hear from you. Here’s how you can reach us..."
      />
      <GetInTouch />
    </>
  );
}
