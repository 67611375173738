import { reduxRequest } from "../utils/reduxRequest";
import { CareerDataLoading, CareerDataSuccess, CareerDropDownDataSuccess } from "./Actions";

export const careerPageDataRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: CareerDataLoading,
    successFunction: CareerDataSuccess,
    url: "/group/career",
    method: "get",
    ...variables,
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};


export const careerDropDownDataRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: CareerDataLoading,
    successFunction: CareerDropDownDataSuccess,
    url: "/group/forms/hr/get",
    method: "get",
    ...variables,
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};
