/* eslint-disable array-callback-return */
import Types from "./Types";

const INIT_STATE = {
  brandsPageData: [],
  brandsDetailsPageData: {},
  isLoading: {
    type: "",
    loading: false,
  },
};

export default function brandsPageReducer(state = INIT_STATE, action) {
  const { type, payload } = action;
  switch (type) {
    case Types.BRANDS_PAGE_DATA_LOADING: {
      return {
        ...state,
        isLoading: payload,
      };
    }
    case Types.BRANDS_PAGE_DATA_SUCCESS: {
      return {
        ...state,
        brandsPageData: payload?.result?.data,
      };
    }
    case Types.BRANDS_DETAILS_PAGE_DATA_SUCCESS: {
      return {
        ...state,
        brandsDetailsPageData: payload?.result?.data,
      };
    }
    default: {
      return state;
    }
  }
}
