import Members from "components/Organisms/About/Members";
import Header from "../../components/Organisms/About/Header";
import Vision from "components/Organisms/About/Vision";
import Mission from "components/Organisms/About/Mission";
import CustomFlexContainer from "components/Atoms/Containers/CustomFlexContainer";
import Values from "components/Organisms/About/Values";
import OurStory from "components/Organisms/About/OurStory";
import Story from "components/Organisms/About/Story";
import { useRef } from "react";
import { Box } from "@mui/material";

export default function About({ aboutPageData, homePageData }) {
  const visionTargetElementRef = useRef(null);
  const missionTargetElementRef = useRef(null);
  const missionImgTargetElementRef = useRef(null);
  const visionImgTargetElementRef = useRef(null);
  return (
    <>
      {/* <Header aboutPageData={aboutPageData} /> */}
      {aboutPageData && (
        <Story aboutPageData={aboutPageData} homePageData={homePageData} />
      )}
      {aboutPageData?.stories && (
        <OurStory
          storyData={aboutPageData?.stories}
          homePageData={homePageData}
        />
      )}
      <Members boardMembers={aboutPageData?.board_members} />
      <CustomFlexContainer
        col
        gap={40}
        sx={{
          background:
            "linear-gradient(145deg, #D5242F -40%, #1F366A 20.31%, #151127 100%)",
          py: 35,
        }}
      >
        <Vision
          visionData={aboutPageData?.vision}
          targetElementRef={visionTargetElementRef}
          imgTargerElementRef={visionImgTargetElementRef}
        />
        <Mission
          missionData={aboutPageData?.mission}
          targetElementRef={missionTargetElementRef}
          imgTargerElementRef={missionImgTargetElementRef}
        />
        {aboutPageData?.values && <Values valuesData={aboutPageData?.values} />}
      </CustomFlexContainer>
    </>
  );
}
