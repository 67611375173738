import React, { useEffect, useMemo, useState, useRef } from "react";

export default function Animation({ targetElementRef, className, delay }) {
  const [isIntersecting, setIsIntersecting] = useState(false);

  const observer = useMemo(
    () =>
      new IntersectionObserver(([entry]) => {
        setIsIntersecting(entry.isIntersecting);
      }),
    []
  );

  useEffect(() => {
    observer.observe(targetElementRef.current);
    return () => {
      observer.disconnect();
    };
  }, [targetElementRef, observer]);

  const lastScrollYRef = useRef(0);

  const handleScroll = () => {
    const { top, bottom } = targetElementRef.current.getBoundingClientRect();
    const isElementInView = top >= 0 && bottom <= window.innerHeight;
    console.log(isElementInView);
    if (
      isIntersecting &&
      // isElementInView &&
      window.scrollY > lastScrollYRef.current
    ) {
      const timeoutId = setTimeout(() => {
        targetElementRef?.current?.classList?.add(className);
      }, delay || 1);

      return () => clearTimeout(timeoutId);
    } 
  };

  useEffect(() => {
    lastScrollYRef.current = window.scrollY;

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [targetElementRef, className, delay, isIntersecting]);

  return null;
}
