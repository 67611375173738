import { Box, Stack } from "@mui/material";
import Section from "../../Molecules/Section";
import AboutUs from "components/Molecules/Home/About";
import MetallicBar from "components/Molecules/Home/MetallicBar";
import HomePageVideo from "./HomePageVideo";

export default function About({ homePageData }) {
  return (
    <Box
      sx={{ position: "relative", mb: { lg: "100px", md: "80px", xs: "70px" }, mt: '-49px' }}
    >
      <MetallicBar numberData={homePageData?.header?.number_data} />
      <HomePageVideo video={homePageData?.header?.video} />
      <Section
        body={homePageData?.about?.description}
        btnContent="SHOW MORE"
        path="/about/"
        content={<AboutUs aboutData={homePageData?.about} />}
        header="ABOUT US"
        additionalText="WE CARE .."
        sx={{
          pb: { md: 20, xs: 20 },
          pt: { lg: 30, xs: 50 },
          backgroundImage:
            "linear-gradient(95.99deg, #151127 4.13%, #1F366A 121.71%)",
        }}
      />
    </Box>
  );
}
