import { Stack, Typography, Dialog } from '@mui/material';

const ModelBox = ({ title, children, closeFunc, openVar, titleStyle, isError }) => {
   return (
      <Dialog
         open={openVar}
         onClose={closeFunc}
         PaperProps={{
            sx: {
               borderRadius: '12px',
               height: 'auto',
               minWidth: { lg: '925px', md: '800px', sm: '500px' },
               textAlign: 'center',
               padding: '20px',
               display:'flex',
               flexDirection:'column',
               gap:15
            },
         }}
      >
         <Stack direction="row" alignItems="center" justifyContent="center" mb={2} mx={1}>
            <Typography sx={titleStyle}  component="h2">
               {title}
            </Typography>
         </Stack>
         {children}
      </Dialog>
   );
};

export default ModelBox;
