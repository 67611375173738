import WrappingContainer from 'components/Atoms/Containers/WrappingContainer';
import { Box, Stack } from '@mui/material';

export default function CustomCard({ children, sx }) {
   return (
      <WrappingContainer sx={{ mt: { xs: 0, md: 5, xl: 15 } }}>
         <Stack
            sx={{
               flexDirection: { md: 'row', xs: 'column-reverse' },
               justifyContent: '',
               alignItems: 'stretch',
               ...sx,
            }}
         >
            {children}
         </Stack>
      </WrappingContainer>
   );
}
