import { Box, Input } from "@mui/material";
import { useRef } from "react";
import calendarIcon from "../../../../assets/icons/calendar.svg";
import date from "../../../../assets/icons/date.svg";
import "./CustomDatePicker.css";

export default function CustomDatePicker({ props }) {
  const { sx, ...rest } = props;

  const dateRef = useRef();
  const handleToggle = () => {
    dateRef.current.type = "date";
    dateRef?.current?.showPicker();
  };

  return (
    <Box
      sx={{
        display: "flex",
        borderRadius: "6px",
        ...sx,
      }}
    >
      <Input
        {...rest}
        onFocus={handleToggle}
        type="text"
        placeholder={props?.placeholder}
        inputRef={dateRef}
        sx={{
         //  color: { md: "white" },
          width: "100%",
          fontSize: { md: "18px", xs: "13px" },
          input: {
            "&::placeholder": {
              color: "#757575 !important",
            },
          },
        }}
        disableUnderline
      />
      <Box
        component={"img"}
        sx={{ display: { md: "block", xs: "none" } }}
        onClick={handleToggle}
        src={calendarIcon}
        alt="calender"
        loading="lazy"
        style={{bgcolor:'red'}}
      />
      <Box
        component={"img"}
        sx={{ display: { md: "none", xs: "block" } }}
        onClick={handleToggle}
        src={date}
        alt="calender"
        loading="lazy"
        style={{bgcolor:'red'}}
      />
    </Box>
  );
}
