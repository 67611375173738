import { createTheme } from '@mui/material';

export const theme = createTheme({
   palette: {
      primary: {
         main: '#202249',
         light: '#1A71AB',
      },
      secondary: {
         main: '#FFBA00',
         sub: '#FFC735',
      },
      light: {
         main: '#70707065',
      },
   },
   theme_palette: {
      darkBlue: '#042353',
      orange: '#FFC72A',
      darkGray: '#131007',
      lightGray: 'rgba(255, 255, 255, 0.70)'
   },
   overrides: {
      MuiPaper: {
         root: {},
      },
   },
   components: {
      MuiButton: {
         defaultProps: {
            disableRipple: true, // No more ripple, on the whole application !
            disableElevation: true,
         },
         styleOverrides: {
            root: {
               // Some CSS
               borderRadius: 0,
               '&:hover': {
                  // backgroundColor: 'primary',
               },
            },
         },
      },
   },
   typography: {
      fontFamily: ['Inter', 'Poppins', 'sans-serif'].join(','),
      h2: {
         fontSize: '3rem',
         '@media (max-width:1235px)': {
            fontSize: '2.3rem',
         },
      },
   },
   spacing: 2,
});
