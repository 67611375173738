import { Box, Typography } from "@mui/material";
import CustomFlexContainer from "components/Atoms/Containers/CustomFlexContainer";
import Animation from "components/Atoms/SharedComponent/Animation";
import { useRef } from "react";

export default function MemberCard({ member, width ,delay}) {
  const { name, position, image } = member;
  const targetElementRef = useRef(null);

  const cardStyle = {
    backgroundColor: "#1B2854",
    color: "white",
    textAlign: "center",
    borderRadius: "8px",
    px: 15,
    py: 15,
  };
  return (
    <>
      <Animation
        targetElementRef={targetElementRef}
        className={"fade-up-animation"}
        delay={delay}
      />

      <Box
        ref={targetElementRef}
        className='fade-up'
        sx={{
          pt: { lg: 40, sm: 30, xs: 10 },
          width: width,
          mb: { md: 0, xs: "30px" },
        }}
      >
        <CustomFlexContainer col sx={cardStyle}>
          <Box
            sx={{
              mt: { lg: -55, md: -40, sm: -50, xs: -30 },
              mb: 5,
              width: "75% !important",
            }}
          >
            {/* <img src={image} width="100%" alt="name" loading="lazy" /> */}
            <Box>
              {image && image?.length > 0 && (
                <Box
                  component="img"
                  width={{ lg: "186px", md: "100%", sm: "60 % ", xs: "100% " }}
                  // width='100%'
                  alt="name"
                  loading="lazy"
                  src={image?.find((img) => img?.size === 1024)?.image_url}
                />
              )}
            </Box>
          </Box>
          <CustomFlexContainer col gap={10}>
            <Typography
              variant="h5"
              component="div"
              fontWeight="600"
              fontSize={{ lg: "26px", sm: "20px", xs: "15px" }}
            >
              {name}
            </Typography>
            <Typography
              variant="h6"
              component="div"
              fontSize={{
                lg: "20px",
                sm: "17px",
                xs: "13px",
              }}
            >
              {position}
            </Typography>
          </CustomFlexContainer>
        </CustomFlexContainer>
      </Box>
    </>
  );
}
