import { Link as MUILink } from "@mui/material";
import { NavLink } from "react-router-dom";

import DropDown from "./DropDown";

export default function NavLinks({ navItems, brandsPageData }) {
  const linkStyle = (index) => {
    return {
      fontSize: { xl: "25px", md: '18px' },
      fontFamily: "Poppins",
      borderRadius: "0px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      color: "rgb(255, 255, 255, 0.75)",
      // minWidth: "170px",
      fontWeight: "500",
      borderRight: index !== navItems.length - 1 && "2px solid #fff",
      px: { lg: 20, md: 10, sm: 5 },
      pr: {
        lg: index === navItems.length - 1 ? 50 : 20,
        md: index === navItems.length - 1 ? 7 : 15,
      },

      //when using NavLink element from react-router-dom
      // .active class will be added to the active link
      "& .active": {
        fontWeight: "700",
        color: "#fff",
      },
    };
  };

  return (
    <>
      {navItems.map((item, index) =>
        item.type === "drop-down" ? (
          <DropDown
            item={item}
            options={item.options}
            key={index}
            sx={linkStyle}
          />
        ) : (
          <MUILink underline="none" key={index} sx={() => linkStyle(index)}>
            <NavLink to={item.path}>{item.name}</NavLink>
          </MUILink>
        )
      )}
    </>
  );
}
