import { reduxRequest } from "../utils/reduxRequest";
import { BrandsDataLoading, BrandsDataSuccess, BrandsDetailsDataSuccess } from "./Actions";

export const brandsPageDataRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: BrandsDataLoading,
    successFunction: BrandsDataSuccess,
    url: "/group/brands",
    method: "get",
    ...variables,
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const brandsDetailsPageDataRequest = ({ id, ...variables }) => {
  const requestData = {
    isLoading: BrandsDataLoading,
    successFunction: BrandsDetailsDataSuccess,
    url: `/group/brands/${id}`,
    loadingType: "details",
    method: "get",
    ...variables,
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};
