import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { brandsPageDataRequest } from "services/modules/brands";
import BrandsTemplate from "templates/brands/Brands.Template";

export default function Brands() {
  const dispatch = useDispatch();
  const { brandsPageData } = useSelector((state) => state.brandsPage);

  useEffect(() => {
    const requestData = {
      body: { jsonrpc: "2.0", params: {} },
    };
    dispatch(brandsPageDataRequest({ ...requestData }));
  }, []);

  return <BrandsTemplate brandsPageData={brandsPageData} />;
}
