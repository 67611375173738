import { Box, Typography } from "@mui/material";
import CustomFlexContainer from "components/Atoms/Containers/CustomFlexContainer";

export default function ContactInfo({ contactInfo }) {
  return (
    <CustomFlexContainer
      gap={{ xs: 15, md: 10, lg: 40 }}
      sx={{ flexDirection: { xs: "column", md: "row" } }}
    >
      {contactInfo.map((c) => (
        <CustomFlexContainer gap={{ md: 10, sm: 7, xs: 3 }} key={c.id}>
          <Box
            component="img"
            width={{ md: "48px", xs: "24px" }}
            height={{ md: "48px", xs: "24px" }}
            src={c.icon}
            alt={c.alt}
            loading="lazy"
          />
          <Typography
            color="white"
            variant="body1"
            sx={{
              fontFamily: "Poppins",
              fontSize: { md: "24px", xs: "14px" },
              fontWeight: "400",
            }}
          >
            {c.info}
          </Typography>
        </CustomFlexContainer>
      ))}
    </CustomFlexContainer>
  );
}
