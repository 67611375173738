import Loader from "components/Atoms/Loader/Loader";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { brandsDetailsPageDataRequest } from "services/modules/brands";
import BrandsDetailsTemplate from "templates/brands/BrandDetails.Template";

export default function BrandsDetailsPage() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { brandsDetailsPageData, isLoading } = useSelector(
    (state) => state.brandsPage
  );

  useEffect(() => {
    const requestData = {
      body: { jsonrpc: "2.0", params: {} },
    };
    dispatch(brandsDetailsPageDataRequest({ id, ...requestData }));
  }, [id]);

    return isLoading?.type == "details" && isLoading?.loading ? (
      <Loader height='80vh' />
    ) : (
      <BrandsDetailsTemplate brandsDetailsData={brandsDetailsPageData} />
    );
}
