import Types from "./Types";

const INIT_STATE = {
  isIceCreamePicOpen: false,
  isValuesCardOpen: false,
  isGalleryImgOpen: false,
  galleryId: 0
};

export default function modelReducer(state = INIT_STATE, action) {
  const { type, payload } = action;

  switch (type) {
    case Types.ICE_CREAME_PIC: {
      return {
        ...state,
        isIceCreamePicOpen: !state.isIceCreamePicOpen,
      };
    }
    case Types.VALUES_CARD: {
      return {
        ...state,
        isValuesCardOpen: !state.isValuesCardOpen,
      };
    }
    case Types.GALLERY_IMG: {
      return {
        ...state,
        isGalleryImgOpen: !state.isGalleryImgOpen,
        galleryId: payload
      };
    }
    default: {
      return state;
    }
  }
}
