import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useTheme } from "@mui/material/styles";

function SliderArrow({ props, Arrow, sx }) {
  const { className, style, onClick } = props;
  const theme = useTheme();
  const { secondary } = theme.palette;

  const arrowStyle = () => {
    return {
      color: secondary.main,
      fontSize: "45px",
      zIndex: "100",
      ...sx,
    };
  };

  return (
    <Arrow
      className={className}
      style={{ ...arrowStyle(), ...style }}
      onClick={onClick}
    />
  );
}

function NextArrow(props) {
  return (
    <SliderArrow
      Arrow={props?.arrowIcon ? props?.arrowIcon : ArrowForwardIcon}
      props={props}
      sx={props?.sx}
    />
  );
}

function PrevArrow(props) {
  return (
    <SliderArrow
      Arrow={props?.arrowIcon ? props?.arrowIcon : ArrowBackIcon}
      props={props}
      sx={props?.sx}
    />
  );
}

export { NextArrow, PrevArrow };
