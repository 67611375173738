import { Box, Button, Typography } from '@mui/material';
import * as Yup from 'yup';

import SectionHeader from 'components/Atoms/Text/SectionHeader';
import CustomFlexContainer from 'components/Atoms/Containers/CustomFlexContainer';
import AppForm from 'components/Atoms/Form/AppForm';
import FormField from 'components/Atoms/Form/AppFormField';
import SubmitButton from 'components/Atoms/Form/SubmitButton';
import { useDispatch, useSelector } from 'react-redux';
import { contactUsFormRequest } from 'services/modules/Forms';
import { useFormik } from 'formik';
import { createPortal } from 'react-dom';
import Modal from "components/Atoms/Model/Model";
import { useState } from 'react';

const initialValues = {
   name: '',
   email: '',
   mobile: '',
   message: '',
};
const phoneRegExp =
   /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const validationSchema = Yup.object().shape({
   name: Yup.string().required().label('Name'),
   email: Yup.string().required().email().label('Email'),
   mobile: Yup.string().required().matches(phoneRegExp, 'Invalid phone number').label('Phone'),
   message: Yup.string().required().label('Message'),
});

const formFields = [
   {
      label: 'Name',
      name: 'name',
      type: 'text',
      placeholder: 'Enter your name',
   },
   {
      label: 'Email',
      name: 'email',
      type: 'text',
      placeholder: 'Enter your email',
   },
   {
      label: 'Phone number',
      name: 'mobile',
      type: 'text',
      placeholder: 'Enter your phone number',
   },
   {
      label: 'Message',
      name: 'message',
      type: 'text',
      placeholder: 'Type what you think',
      minRows: 4,
      multiline: true,
   },
];

export default function ContactForm() {
   const dispatch = useDispatch()
   const handleFormSubmit = (values, { setSubmitting }) => {
      //to simulate sending form data
      const requestData = {
         body: { jsonrpc: "2.0", params: { ...values, "subject": "subject_Test", } },
         action: handleOpen()
      };
      dispatch(contactUsFormRequest({ ...requestData }))
      setTimeout(() => {
         setSubmitting(false); //disabling submit btn in the meanwhile
      }, 1000);
   };


   const formik = useFormik({
      initialValues: initialValues,
      onSubmit: handleFormSubmit,
      validationSchema,
   });

   const [modalData, setModalData] = useState({ title: 'Thank you for get in touch with us', type: 'message' })

   //modal
   const [isOpen, setIsOpen] = useState(false);
   const handleOpen = () => setIsOpen(true);

   const handleClose = () => {
      setIsOpen(false);
   };


   return (
      <>
         {createPortal(
            <Modal
               openVar={isOpen}
               closeFunc={handleClose}
               title={modalData?.title}
               titleStyle={{ color: modalData?.type == 'message' ? "#1A71AB" : 'red', fontSize: { md: "36px", sm: '25px', xs: '20px' }, fontWeight: 600 }}
            >
               {modalData?.type == 'message' && <>
                  <Typography
                     id="modal-modal-description"
                     sx={{ mt: 2, fontSize: { md: "32px", sm: '20px', xs: '15px' } }}
                  >
                     We have Received your message and appreciate your interest in
                     message our team 
                  </Typography>
                  <Button
                     variant="contained"
                     color="secondary"
                     onClick={handleClose}
                     sx={{
                        width: "fit-content",
                        color: "white",
                        m: "0 auto",
                        fontWeight: "700",
                     }}
                  >
                     BACK
                  </Button>
               </>}
            </Modal>,
            document.body
         )}
         <>
            <Box
               component="div"
               sx={{
                  background: { md: 'linear-gradient(0deg, #1B2853 0%, #1B2853 100%)' },
                  bgcolor: { md: 'none', xs: '#172549' },
                  p: { md: 20, xs: 7 },
                  borderRadius: { md: 0, xs: '8px' }
               }}
            >
               <SectionHeader sx={{ fontSize: { md: '50px', sm: '30px', xs: '15px' }, mb: '15px' }}>GET IN TOUCH</SectionHeader>
               {/* <AppForm
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values) => {
               handleFormSubmit(values);
            }}
         > */}
               <form onSubmit={formik.handleSubmit}>
                  <CustomFlexContainer col sx={{ alignItems: 'stretch' }} gap={12}>
                     {formFields.map((field, i) => (
                        <FormField formik={formik} field={field} key={i} />
                     ))}
                     <SubmitButton>SEND</SubmitButton>
                  </CustomFlexContainer>
               </form>
               {/* </AppForm> */}
            </Box>
         </>
      </>
   );
}
