import axios from "axios";
import { fileUploadFail, fileUploadLoading, fileUploadSuccess, fileUploadedLoading } from "./Actions";
import { store } from "services/store";
import { reduxRequest } from "../utils/reduxRequest";

// const token = localStorage.getItem("TOKEN");
export const getPresignedUrlRequest = ({ ...variables }) => {
  const requestData = {
    ...variables,
    isLoading: fileUploadLoading,
    loadingType: "upload",
    failFunction: fileUploadFail,
    url: "/group/presigned",
    method: "post",
    body: variables.body,

    action: (data) => {
      store.dispatch(
        uploadRequest({
          put_url: data?.result?.data?.put_url,
          file: variables.body.file,
          fileUrl: data?.result?.data?.get_url,
          action: variables.action,
        })
      );
    },
  };
  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};
export const uploadRequest = ({ ...variables }) => {
  const requestData = {
    ...variables,
    isLoading: fileUploadedLoading,
    loadingType: "uploadOnServer",
    url: variables.put_url,
    body: variables.file,
    method: "put",
    action: () => {
      store.dispatch(fileUploadSuccess(variables?.fileUrl));
      variables?.action(variables.fileUrl);
      // set token in headers after uploading success
      // axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    },
  };
  // delete axios.defaults.headers.common["Authorization"];
  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};
