import { useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { Box, Button, Typography } from "@mui/material";
import UploadButton from "./UploadButton";
// import Modal from '../BasicModal';
import Modal from "components/Atoms/Model/Model";
import { useDispatch, useSelector } from "react-redux";
import { careersFormRequest } from "services/modules/Forms";
import { getPresignedUrlRequest } from "services/modules/uploadFiles";
import { useFormik } from "formik";
import UploadInput from "components/Atoms/Form/UploadInput";
import * as Yup from "yup";
import { resetValuesSuccess } from "services/modules/uploadFiles/Actions";

export default function UploadResume({ title, id }) {
   const [isUploading, setIsUploading] = useState(false);
   const [fileName, setName] = useState("");
   const [key, setKey] = useState("");
   const [modalData, setModalData] = useState({ title: 'Thank you for applying to Fusteka', type: 'message' })

   //modal
   const [isOpen, setIsOpen] = useState(false);
   const handleOpen = () => setIsOpen(true);

   const handleClose = () => {
      setIsOpen(false);
      setName("");
   };
   const dispatch = useDispatch();
   let { files, load: { loading } } = useSelector((state) => state?.uploadFile);

   useEffect(() => {
      setKey(fileName);
   }, [fileName]);

   const initialValues = {
      cv_url: "",
      position_applied_for: title,
   };

   const onSubmit = (values) => {
      const requestData = {
         body: { jsonrpc: "2.0", params: { ...values } },
         action: () => {
            setIsOpen(true)
            setModalData({ title: 'Thank you for applying to Fusteka', type: 'message' })
            dispatch(resetValuesSuccess())
         },
      };
      files && dispatch(careersFormRequest(requestData));
   };

   const validationSchema = {
      cv_url: Yup.string().required("Required!"),
      position_applied_for: Yup.string().required("Required!"),
   };

   const formik = useFormik({
      initialValues,
      onSubmit,
   });

   const uploadFile = (e) => {
      if (e?.target?.files[0]?.type?.slice(e?.target?.files[0]?.type?.indexOf('/') + 1) != 'pdf') {
         setIsOpen(true)
         setModalData({ title: 'File must be pdf', type: 'error' })
         return;
      }
      setIsUploading(true);
      dispatch(
         getPresignedUrlRequest({
            body: {
               file: e.target.files[0],
               params: {
                  extension: e.target.files[0]?.name.substring(
                     e.target.files[0]?.name.lastIndexOf(".") + 1,
                     e.target.files[0]?.name?.length
                  ),
               },
            },
            action: (data) => {
               setIsUploading(false);
               setName(e?.target?.files[0]?.name || "");
               formik?.setValues({ ...formik?.values, cv_url: data });
            },
         })
      );
   };

   return (
      <>
         {createPortal(
            <Modal
               openVar={isOpen}
               closeFunc={handleClose}
               title={modalData?.title}
               titleStyle={{ color: modalData?.type == 'message' ? "#1A71AB" : 'red', fontSize: { md: "36px", sm: '25px', xs: '20px' }, fontWeight: 600 }}
            >
               {modalData?.type == 'message' && <>
                  <Typography
                     id="modal-modal-description"
                     sx={{ mt: 2, fontSize: { md: "32px", sm: '20px', xs: '15px' } }}
                  >
                     We have Received your resume and appreciate your interest in
                     joining our team ,we will be in touch with you soon
                  </Typography>
                  <Button
                     variant="contained"
                     color="secondary"
                     onClick={handleClose}
                     sx={{
                        width: "fit-content",
                        color: "white",
                        m: "0 auto",
                        fontWeight: "700",
                     }}
                  >
                     BACK
                  </Button>
               </>}
            </Modal>,
            document.body
         )
         }

         <>
            <Box
               sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  p: 6,
                  // border: () => (formik?.errors  ? '2px dashed red' : '2px dashed white'),
                  border: () => "2px dashed white",
               }}
            >
               <>
                  {/* <input {...getInputProps()} /> */}
                  {fileName.length > 0 ? (
                     <UploadButton
                        submit={formik.handleSubmit}
                        fileName={fileName}
                        id={id}
                     />
                  ) : (
                     <Typography fontSize={{ md: "18px", sm: '16px', xs: '13px' }} >No file selected</Typography>
                  )}
                  <Box
                     sx={{ display: "flex", justifyContent: "end" }}
                  // {...getRootProps()}
                  >
                     <Box>
                        <UploadInput
                           btnText="CHOOSE FILE"
                           btnStyle={{
                              color: "white",
                              fontSize: { md: "18px", sm: '16px', xs: '11px' },
                              fontWeight: "700",
                              bgcolor: "#EDB41D",
                              p: "6px 16px",
                              width: "100%",
                           }}
                           change={uploadFile}
                           uploadLoading={loading}
                           id={id}
                        />
                     </Box>
                  </Box>
               </>
            </Box>
         </>
         {/* {formik?.errors && <Typography color="red">{formik?.errors}</Typography>} */}
      </>
   );
}
