import { Box } from "@mui/material";

import BrandHeader from "components/Molecules/Brands/BrandHeader";
import CompanyInfo from "components/Molecules/Brands/CompanyInfo";
import ProductsPortfolio from "components/Molecules/Brands/ProductsPortfolio";
import { brands } from "data";
//temporary
import biscoSandwich from "../../assets/images/brands/fusteka/bisco-sandwich.png";
import biscoSandwichLogo from "../../assets/images/brands/fusteka/Bisco chocolate new siz 2.png";
import CustomFlexContainer from "components/Atoms/Containers/CustomFlexContainer";

const { logo, brandHeaderImage, name, about, url, info, products } =
  brands["fusteka"];
const brand = {
  logo,
  brandHeaderImage,
  name,
  about,
  url,
};

export default function Fusteka() {
  return (
    <Box>
      <BrandHeader brand={brand} />
      <CustomFlexContainer
        sx={{
          position: "relative",
          backgroundImage:
            "linear-gradient(145deg, #D5242F -40%, #1F366A 20.31%, #151127 100%)",
        }}
      >
        <Box sx={{ position: "absolute", left: 60, top: 40, width: "20vw" }}>
          <img
            src={biscoSandwichLogo}
            alt="bisco sandwich"
            width="100%"
            loading="lazy"
          />
        </Box>

        <Box sx={{ position: "absolute", right: 60, bottom: 40 }}>
          <img src={logo.src} alt={logo.alt} loading="lazy" />
        </Box>
        <Box sx={{ width: "50vw" }}>
          <img
            src={biscoSandwich}
            alt="bisco sandwich"
            width="100%"
            loading="lazy"
          />
        </Box>
      </CustomFlexContainer>
      <CompanyInfo info={info} />
      <ProductsPortfolio products={products} />
    </Box>
  );
}
