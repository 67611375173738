import { Box, Typography } from "@mui/material";
import { images } from "assets/AssetHelper";
import CustomFlexContainer from "components/Atoms/Containers/CustomFlexContainer";
import { animate, useMotionValue, useTransform } from "framer-motion";
import { useEffect, useState } from "react";
import { motion } from "framer-motion";

export default function MetallicBar({ numberData }) {
  const content = [
    { id: 1, title: "20", description: "Production lines" },
    { id: 2, title: "12000+", description: "tons of frozen food per year" },
    { id: 3, title: "25", description: "Products" },
  ];
  const [isInView, setIsInView] = useState(false);
  const count = useMotionValue(0);
  const rounded = useTransform(count, (latest) => Math.round(latest));
  const NumberCom = ({ number }) => {
    const count = useMotionValue(0);
    const rounded = useTransform(count, (latest) => Math.round(latest));
    useEffect(() => {
      const controls = animate(count, number, { duration: 1 });
      return controls.stop;
    }, []);
    return (
      <>
        <motion.span
          whileInView={() => {
            setIsInView(true);
            return {};
          }}
        >
          <motion.span>{rounded}</motion.span>
        </motion.span>
      </>
    );
  };

  return (
    <Box
      sx={{
        backgroundImage: `url(${images.metallicBar})`,
        backgroundSize: "100% 100%",
        width: { xs: "85%", sm: "55%", md: "60%" },
        // height: "120px",
        position: "absolute",
        top: "30px",
        left: "50%",
        py: { lg: "24px" },
        transform: "translate(-50%, -50%)",
      }}
    >
      <CustomFlexContainer
        sx={{
          mx: "auto",
          height: "100px",
          justifyContent: "space-between",

          width: { xs: "90%", md: "80%" },
        }}
      >
        {numberData?.map((data) => (
          <CustomFlexContainer key={data?.id} col sx={{ textAlign: "center" }}>
            <Typography fontSize={{ xs: "21px", md: "41px" }}>
              <NumberCom number={data?.value} />
              {data?.title == "Tons of frozen food per year" && "+"}
            </Typography>
            <Typography
              fontWeight="700"
              sx={{ textTransform: "capitalize" }}
              fontSize={{ md: "19px", xs: "8px" }}
            >
              {data?.title}
            </Typography>
          </CustomFlexContainer>
        ))}
      </CustomFlexContainer>
    </Box>
  );
}
