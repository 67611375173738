import { useState } from "react";
import SliderSection from "../SliderSection/SliderSection";

export default function OurBusiness({ brandsData, brands }) {
  const [isActive, setIsActive] = useState(1);
  const [isCurrent, setIsCurrent] = useState();
  
  return (
    <SliderSection
      sliderData={brandsData}
      title="OUR BUSINESS"
      setIsActive={setIsActive}
      isActive={isActive}
      isCurrent={isCurrent}
      setIsCurrent={setIsCurrent}
    />
  );
}
