import { Input, Box, Typography } from "@mui/material";
import { useDropzone } from "react-dropzone";
import CustomizableDatePicker from "components/Atoms/Input/DatePicker/CustomizableDatePicker";
import { useDispatch } from "react-redux";
import { getPresignedUrlRequest } from "services/modules/uploadFiles";
import { useState } from "react";
import UploadInput from "./UploadInput";
import SearchableSelect from "../Input/SearchableSelect/SearchbleSelect";

export default function CustomInput(props) {
  let {
    type,
    sx,
    uploadFile,
    values,
    fileName,
    formik,
    field,
    uploadLoading,
    id,
  } = props;
  return (
    <>
      {(() => {
        switch (type) {
          //!text
          case "text":
            return (
              <Input
                {...props}
                sx={{
                  ...sx,
                  width: "100%",
                  input: {
                    "&::placeholder": {
                      color: "#757575 !important",
                    },
                  },
                }}
              />
            );
          //!file
          case "file":
            return (
              <UploadInput
                {...props}
                change={uploadFile}
                containerStyle={props?.sx}
                btnText={fileName ? fileName : "Choose file ..."}
                btnStyle={{
                  fontSize: { md: "20px", xs: "15px" },
                  color: "rgba(0, 0, 0, 0.3)   ",
                }}
                textValue={values?.cv_url}
                uploadLoading={uploadLoading}
                id={id}
              />
            );
          //!date
          case "date":
            return <CustomizableDatePicker props={props} />;
          case "dropdown":
            return (
              <SearchableSelect
                menuPlacement="bottom"
                name={field.name}
                options={field?.options}
                keyValue="name"
                placeholder={field?.placeholder}
                onChange={(val) => {
                  formik.setValues({
                    ...formik?.values,
                    [field?.name]: val,
                  });
                }}
                value={formik.values[field.name]}
              />
            );
          default:
            return null;
        }
      })()}
    </>
  );
}
