import { useDispatch, useSelector } from 'react-redux';
import CareersTemplate from '../../templates/careers/Careers.Template';
import { useEffect } from 'react';
import { careerDropDownDataRequest, careerPageDataRequest } from 'services/modules/career';
export default function Careers() {

   const { careerPageData, educationDegreesData, jobPositionsData } = useSelector(state => state.careerPage)
   const dispatch = useDispatch();
   useEffect(() => {
      const requestData = {
         body: { "jsonrpc": "2.0", "params": {} }
      };
      dispatch(careerPageDataRequest({ ...requestData }));
      dispatch(careerDropDownDataRequest({ ...requestData }));
   }, []);

   return <CareersTemplate
      careerPageData={careerPageData}
      educationDegreesData={educationDegreesData}
      jobPositionsData={jobPositionsData}
   />;
}
