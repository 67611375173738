import { Box, Grid, Typography } from "@mui/material";
import WrappingContainer from "../Atoms/Containers/WrappingContainer";
import CustomButton from "../Atoms/Buttons/CustomButton";
import SectionHeader from "components/Atoms/Text/SectionHeader";
import { useNavigate } from "react-router-dom";
import Motion from "components/Atoms/SharedComponent/Motion";
import {
  fadeInLeft,
  fadeInRight,
} from "components/Atoms/SharedComponent/aniamtionDirection";

export default function Section({
  sx,
  header,
  body,
  content,
  btnContent,
  path,
  additionalText,
}) {
  const navigate = useNavigate();
  return (
    <Box sx={sx}>
      <WrappingContainer>
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          direction={"row"}
          // gap={15}
        >
          <Motion
            {...fadeInLeft({
              width: { lg: "45%", md: "48%", sm: "50%", xs: "47%" },
              duration: 1.5,
            })}
            width='50%'
            // style={{ alignSelf: "self-start" }}
            // alignSelf={"self-start"}
          >
            <Grid item sx={{ my: "auto" }}>
              <Grid container gap={{ md: "36px", sm: 10, xs: 4 }}>
                <Grid item>
                  {header && (
                    <SectionHeader sx={{ mb: "36px" }}>{header}</SectionHeader>
                  )}
                </Grid>
                <Grid item xs={12} className="about-desc">
                  {body && (
                    <Typography
                      variant="body1"
                      color="white"
                      sx={{
                        fontFamily: "Poppins",
                        fontSize: { md: "26px", sm: "18px", xs: "14px" },
                        fontWeight: "400",
                      }}
                      xl={12}
                      dangerouslySetInnerHTML={{ __html: body }}
                    />
                  )}
                </Grid>
                <Grid item sx={{ display: { lg: "block", xs: "none" } }}>
                  {btnContent && (
                    <CustomButton
                      onClick={() => {
                        path && navigate(`${path}`);
                      }}
                    >
                      {btnContent}
                    </CustomButton>
                  )}
                </Grid>
              </Grid>
              {additionalText && (
                <Box>
                  <Typography
                    sx={{
                      fontSize: { md: "100px", sm: "40px", xs: "29px" },
                      color: "#fff",
                      fontFamily: "Hagin Caps Thin, sans-serif",
                      fontWeight: "100",
                      letterSpacing: "4px",
                      mt: { md: 0, xs: 20 },
                    }}
                  >
                    {additionalText}
                  </Typography>
                </Box>
              )}
            </Grid>
          </Motion>
          <Motion
            {...fadeInRight({
              width: { lg: "45%", md: "48%", sm: "50%", xs: "47%" },
              duration: 1.5,
            })}
            width='50%'
          >
            <Grid Grid item>
              {content}
            </Grid>
          </Motion>
          )
        </Grid>
        {btnContent && (
          <Grid item xs={5} sx={{ display: { lg: "none", xs: "block" } }}>
            <CustomButton
              onClick={() => {
                path && navigate(`${path}`);
              }}
            >
              {btnContent}
            </CustomButton>
          </Grid>
        )}
      </WrappingContainer>
    </Box>
  );
}

