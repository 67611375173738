import { useRef, useState } from "react";
import { Link as MUILink, Typography } from "@mui/material";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import {
  MenuList,
  MenuItem,
  Popper,
  Paper,
  Grow,
  ClickAwayListener,
} from "@mui/material";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";

export default function SplitButton({ item, options, sx }) {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const { pathname } = useLocation();

  const handleClick = (e) => {
    setOpen(!open);
  };

  const handleMenuItemClick = (option) => {
    console.log(option);
    setOpen(false);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const navigate = useNavigate();
  return (
    <>
      <MUILink underline="none" sx={sx} ref={anchorRef}>
        <NavLink
          to={item.path}
          style={{ display: "flex", alignItems: "center", bgcolor: "blue" }}
        >
          {item.name}
        </NavLink>
        <KeyboardArrowDownOutlinedIcon
          fontSize="large"
          sx={{
            color: pathname?.includes("brands") && "white",
          }}
          onClick={handleClick}
        />
      </MUILink>

      <Popper
        sx={{ zIndex: 1 }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {options.map((option, index) => (
                    <MenuItem
                      key={index}
                      onClick={() => handleMenuItemClick(option)}
                    >
                      <MUILink>
                        <Typography
                          onClick={(_) => navigate(`brands/${option?.id}`)}
                        >
                          {option.name}
                        </Typography>
                      </MUILink>
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}
