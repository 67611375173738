import { Box, Grid } from "@mui/material";
import WrappingContainer from "components/Atoms/Containers/WrappingContainer";
import Animation from "components/Atoms/SharedComponent/Animation";
import ContactForm from "components/Molecules/Contact/ContactForm";
import Location from "components/Molecules/Contact/Location";
import { useRef } from "react";

export default function GetInTouch() {
  const targetElementRef = useRef(null);
  const locationTargetElementRef = useRef(null);
  return (
    <Box sx={{ backgroundColor: "#151127", py: 35 }}>
      <WrappingContainer>
        <Grid
          direction={{ md: "row", xs: "column-reverse" }}
          container
          justifyContent="space-between"
        >
          <Animation
            targetElementRef={targetElementRef}
            className={"move"}
            delay={200}
          />

          <Grid
            item
            md={7}
            xs={12}
            className="moving-from-left"
            ref={targetElementRef}
          >
            <ContactForm />
          </Grid>
          <Animation
            targetElementRef={locationTargetElementRef}
            className={"move"}
            delay={200}
          />
          <Grid
            item
            md={4}
            xs={12}
            className="moving-from-right"
            ref={locationTargetElementRef}
          >
            <Location />
          </Grid>
        </Grid>
      </WrappingContainer>
    </Box>
  );
}
