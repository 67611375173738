import PagesHeader from "components/Molecules/PagesHeader";
import { images } from "assets/AssetHelper";
import { useState } from "react";
import SliderSection from "components/Organisms/SliderSection/SliderSection";

export default function Brands({ brandsPageData }) {
  const [sistersCompantIsActive, setSistersCompantIsActive] = useState(1);
  const [sistersCompantIsCurrent, setSistersCompantIsCurrent] = useState();
  const [isActive, setIsActive] = useState(1);
  const [isCurrent, setIsCurrent] = useState();

  return (
    <>
      <PagesHeader
        img={images?.brandsBg}
        title="OUR BRANDS"
        description="Our group accomplished and offered many projects and services in the fields of ice cream production & retail sales, frozen food, construction and supply."
      />
      <SliderSection
        sliderData={brandsPageData?.filter((ele) => ele?.type == "group")}
        title="Group Companies"
        setIsActive={setIsActive}
        isActive={isActive}
        isCurrent={isCurrent}
        setIsCurrent={setIsCurrent}
      />
      <SliderSection
        sliderData={brandsPageData?.filter((ele) => ele?.type == "sister")}
        title="Sister Companies"
        setIsActive={setSistersCompantIsActive}
        isActive={sistersCompantIsActive}
        isCurrent={sistersCompantIsCurrent}
        setIsCurrent={setSistersCompantIsCurrent}
      />
    </>
  );
}
