import Types from "./Types";

export const BrandsDataSuccess = (payload) => ({
  type: Types.BRANDS_PAGE_DATA_SUCCESS,
  payload,
});

export const BrandsDataLoading = (payload) => ({
  type: Types.BRANDS_PAGE_DATA_LOADING,
  payload,
});


export const BrandsDetailsDataSuccess = (payload) => ({
  type: Types.BRANDS_DETAILS_PAGE_DATA_SUCCESS,
  payload,
});
