import Types from "./Types";

export const HomeDataSuccess = (payload) => ({
  type: Types.HOME_PAGE_DATA_SUCCESS,
  payload,
});

export const HomeDataLoading = (payload) => ({
  type: Types.HOME_PAGE_DATA_LOADING,
  payload,
});
