import { Box, Button, CircularProgress, Grid, Typography } from "@mui/material";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { getPresignedUrlRequest } from "services/modules/uploadFiles";

function UploadInput({
  placeholder,
  btnStyle,
  inputStyle,
  btnText,
  change,
  id,
  bgcolor,
  title,
  textValue,
  containerStyle,
  variant,
  disabled,
  className,
  uploadLoading,
  formik,
}) {

  console.log(btnText);
  return (
    <Grid sx={containerStyle} container>

      <Grid item xs={12} className={className}>
        <Box
          variant={variant}
          sx={inputStyle}
          disabled={disabled}
          placeholder={placeholder}
          value={textValue}
          // width="100%"
          // height="56px"
          bgcolor={bgcolor}
        >
          <Typography fontSize='20px' color={'rgba(255, 255, 255, 0.40)'} >{textValue && textValue}</Typography>
        </Box>
      </Grid>
      <Grid item xs={12}>
        {uploadLoading ? <CircularProgress sx={{ color: { md: '#eee', xs: '#777' } }} /> : <label htmlFor={`upload-${id}`}>
          <Box component="span" variant="contained" sx={{ cursor: "pointer", ...btnStyle }}>
            {btnText}
          </Box>
          <input
            id={`upload-${id}`}
            name={`upload-${id}`}
            type="file"
            style={{ display: "none" }}
            accept="application/pdf"
            onChange={e => change(e)}
          />
        </label>}
      </Grid>
    </Grid>
  );
}

export default UploadInput;
