import { Box } from "@mui/material";
import Animation from "components/Atoms/SharedComponent/Animation";
import { useRef } from "react";

export default function CardMedia({ src, sx, alt, targetElementRef }) {
  return (
    <>
      <Animation targetElementRef={targetElementRef} className={"move"} />
      <Box
        sx={{
          mt: "auto",
          width: { lg: "175%", md: "150%" },
          ...sx,
        }}
        ref={targetElementRef}
        className="moving-from-right"
      >
        <img
          src={src}
          width="100%"
          style={{ display: "flex", borderRadius: "10px" }}
          alt={alt}
          loading="lazy"
        />
      </Box>
    </>
  );
}
