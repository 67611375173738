import { Box } from '@mui/material';

export default function CustomFlexContainer({ justifyContent = 'center',children, gap = 0, col, sx }) {
   return (
      <Box
         sx={{
            display: 'flex',
            flexDirection: () => col ? "column" : 'row',
            justifyContent: justifyContent,
            alignItems: 'center',
            gap,
            ...sx,
         }}
      >
         {children}
      </Box>
   );
}
