import Types from "./Types";

export const fileUploadSuccess = (payload) => ({
  type: Types.FILE_UPLOAD_SUCCESS,
  payload,
});
export const fileUploadFail = (payload) => ({
  type: Types.FILE_UPLOAD_FAIL,
  payload,
});
export const fileUploadLoading = (payload) => ({
  type: Types.FILE_UPLOAD_LOADING,
  payload,
});
export const fileUploadedLoading = (payload) => ({
  type: Types.GET_FILE_UPLOADED_LOADING,
  payload,
});
export const resumeSuccess = (payload) => ({
  type: Types.RESUME_SUCCESS,
  payload,
});
export const resetValuesSuccess = (payload) => ({
  type: Types.RESET_VALUES,
  payload,
});
