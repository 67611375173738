import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";

export default function Loader({ height }) {
  return (
    <Box
      sx={{
        display: "flex",
        height: height || "100%",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CircularProgress  sx={{color:'#042353'}} />
    </Box>
  );
}
