import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { newsDetailsDataRequest, newsPageDataRequest } from "services/modules/news";
import NewsDetailsTemplate from "templates/news/NewsDetails.Template";

export default function NewsDetailspage() {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { newsDetailsData, newsPageData } = useSelector((state) => state?.newsPage);
  useEffect(() => {
    const requestData = {
      body: { jsonrpc: "2.0", params: {} },
      id
    };
    dispatch(newsDetailsDataRequest({ ...requestData }));
    dispatch(newsPageDataRequest({ ...requestData }));
  }, [id]);


  return (
    <NewsDetailsTemplate newsDetailsData={newsDetailsData} newsPageData={newsPageData} />
  );
}
