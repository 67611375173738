import {
  CircularProgress,
  FormHelperText,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React from "react";
import Select, { components } from "react-select";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

const DropdownIndicator = (props) => {
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        {props.selectProps.menuIsOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </components.DropdownIndicator>
    )
  );
};

const SearchableSelect = ({
  options,
  onChange,
  value,
  label,
  className,
  error,
  placeholder,
  modal,
  isLoading = false,
  keyValue = "name",
  menuPlacement = "auto",
}) => {
  const isTablet = useMediaQuery("(max-width:899px)");
  const handelOptions = options?.map((val) => {
    return {
      label: val?.[keyValue],
      value: val?.id,
    };
  });
  const labelStyle = {
    color: isTablet ? "#09c !important" : "#fff !important",
    // whiteSpace: "nowrap",
    // fontSize: "16px",
    // lineHeight: "15px",
    // fontWeight: 500,
    // mb: "10px",
    // color: "#333F51",
    // fontSize: "16px",
    // lineHeight: "15px",
    fontWeight: 500,
    mb: "8px",
  };
  const controlStyles = (baseStyles, state) => ({
    ...baseStyles,
    padding: isTablet ? "5px 0" : "14px 0 ",
    borderLeft: "none",
    backgroundColor: "#fff",
    border: 0,
    borderWidth: "0 !important",
    ...(modal
      ? {
          border: "none",
          borderBottom: error
            ? "1px solid #d32f2f"
            : state?.isFocused
            ? "1px solid #000"
            : "1px solid grey",
          borderRadius: 0,
        }
      : {
          borderRadius: "8px",
          border: error
            ? "1px solid #d32f2f"
            : state?.isFocused
            ? "1px solid #000"
            : "1px solid #CBCBCB",
        }),
    outline: "none",
    boxSizing: "border-box",
    "&:hover": { borderColor: "black" },
    boxShadow: "none",
  });

  const customStyles = {
    singleValue: (provided) => ({
      ...provided,
      color: isTablet ? "#000" : "#fff", // Change the input value color here
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "rgba(0, 0, 0, 0.3)",
      fontSize: !isTablet && "20px",
    }),
  };
  return (
    <Stack className="dropdown" sx={{ position: "relative", zIndex: 10 }}>
      {label && (
        <Typography variant="h3" sx={labelStyle}>
          {label}
        </Typography>
      )}
      <Select
        loadingMessage={() => <CircularProgress color="primary" size={30} />}
        isLoading={isLoading}
        menuPlacement={menuPlacement}
        minMenuHeight={300}
        menuPortalTarget={document.body}
        styles={{
          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
          width: "100%",
          control: controlStyles,
          ...customStyles,
        }}
        components={{ DropdownIndicator }}
        className={className}
        options={handelOptions}
        onChange={onChange}
        value={value?.label ? value : null}
        placeholder={placeholder}
      />
      {error && (
        <FormHelperText
          sx={{ color: "error.main", marginLeft: 0, textAlign: "start" }}
        >
          required
        </FormHelperText>
      )}
    </Stack>
  );
};
export default SearchableSelect;
