import { Box } from '@mui/material';

import gradientBackground from '../../../assets/images/backgrounds/gradient-background.png';
import WrappingContainer from 'components/Atoms/Containers/WrappingContainer';
import SectionHeader from 'components/Atoms/Text/SectionHeader';
import CustomFlexContainer from 'components/Atoms/Containers/CustomFlexContainer';
import ContactInfo from 'components/Molecules/Footer/ContactInfo';
import SocialMedia from 'components/Molecules/Footer/SocialMedia';

export default function ContactUs({ contactInfo, socialMediaIcons }) {
   return (
      <Box
         sx={{
            backgroundImage: `url(${gradientBackground})`,
            backgroundPosition: 'center',
            backgroundSize: '100% 100%',
            backgroundRepeat: 'no-repeat',
            pt: { md: 65, sm: 30, xs: 20 },
            pb: { md: 40, sm: 25, xs: 15 },
            textAlign: 'center',
         }}
      >
         <WrappingContainer>
            <CustomFlexContainer col gap={{ md: 30, sm: 20, xs: 10 }}>
               <SectionHeader>CONTACT US</SectionHeader>
               <ContactInfo contactInfo={contactInfo} />
               <SocialMedia socialMediaIcons={socialMediaIcons} />
            </CustomFlexContainer>
         </WrappingContainer>
      </Box>
   );
}
