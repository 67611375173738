import { Box } from '@mui/material';
import BrandHeader from 'components/Molecules/Brands/BrandHeader';
import AboutCompany from 'components/Molecules/Brands/AboutCompany';
import { brands } from 'data';

export default function Alameri() {
   const { logo, brandHeaderImage, name, about, url, info, products } = brands['alAmeri'];
   const brand = { logo, brandHeaderImage, name, about, url };

   return (
      <Box>
         <BrandHeader brand={brand} />
         <AboutCompany
            info={info}
            body="Al AMERI company is a One of the largest companies in trading and contracting field established in 2004 
                  ,Basra Iraq, the first company which started with light steel structure which has less cost and less time ."
         />
      </Box>
   );
}
