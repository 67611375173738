import CustomFlexContainer from 'components/Atoms/Containers/CustomFlexContainer';
import { Box, Typography } from '@mui/material';
import inova from "../../../assets/images/inova.svg"
export default function CopyRights() {
   return (
      <CustomFlexContainer sx={{ py: 10 }} gap={10}>
         <Typography variant="body2" color="#525252" fontSize={{ md: "23px", sm: '19px', xs: '13px' }} fontWeight='400'>
            Developed by
         </Typography>
         <Box
            component='img'
            width={{ md: ' 96px', xs: '51px' }}
            height={{ md: '17px', xs: '10px' }}
            src={inova}
            alt="inova"
            loading="lazy"
         />
      </CustomFlexContainer>
   );
}
