import { Typography } from '@mui/material';

export default function SectionHeader({ children, sx }) {
   return (
      <Typography
         variant="h2"
         sx={{
            background: 'linear-gradient(180deg, #FFC757 0%, #FFC700 100%)',
            backgroundClip: 'text',
            textFillColor: 'transparent',
            fontFamily: 'Inter',
            fontWeight: '900',
            fontSize: { md: '58px', sm: '35px', xs: '19px' },
            letterSpacing: '4px',
            textTransform:'uppercase',
            ...sx,
         }}
      >
         {children}
      </Typography>
   );
}
