import ContactUs from './ContactUs';
import CopyRights from './CopyRights';
import GetUpdatedForm from './GetUpdatedForm';

export default function Footer({contactInfo,socialMediaIcons}) {
   return (
      <>
         <GetUpdatedForm />
         <ContactUs contactInfo={contactInfo} socialMediaIcons={socialMediaIcons}/>
         <CopyRights />
      </>
   );
}
