import { useState } from "react";
import { Typography, Box, Stack } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";

import WrappingContainer from "components/Atoms/Containers/WrappingContainer";
import CustomFlexContainer from "components/Atoms/Containers/CustomFlexContainer";

import SectionHeader from "components/Atoms/Text/SectionHeader";
import CustomSlider from "components/Molecules/Slider/CustomSlider";
import MetallicBar from "components/Molecules/Home/MetallicBar";

export default function OurStory({ storyData, homePageData }) {
  const [isActive, setIsActive] = useState(1);
  return (
    <Box
      pt="3rem"
      sx={{
        background:
          "radial-gradient(circle, rgba(40,86,166,1) 0%, rgba(0,0,0,1) 70%)",
      }}
    >
      <WrappingContainer
        sx={{
          textAlign: "center",
          py: 40,
        }}
      >
        <Box
          sx={{
            position: "relative",
            mb: { lg: "120px", md: "80px", xs: "70px" },
            mt: "-154px",
          }}
        >
          <MetallicBar numberData={homePageData?.header?.number_data} />
        </Box>
        <CustomFlexContainer col gap={15}>
          <SectionHeader sx={{ pt: "2rem" }}>OUR STORY</SectionHeader>
          <Box sx={{ width: { lg: "95%", md: "90%", xs: "90%" } }}>
            <CustomSlider
              slidesToShowXl={3}
              setIsActive={setIsActive}
              length={storyData?.length - 1}
              className="our-story"
              infinite={storyData?.length > 3 ? true : false}
              xsInfinite={storyData?.length > 1 ? true : false}
              maxWidth="xl"
              containerPadding="0 !important"
            >
              {storyData?.map((story, i) => (
                <Card
                  sx={{
                    borderRadius: "8px",
                    overflow: "hidden",
                    scale: { md: isActive == i ? "1" : "0.84", xs: "0.74" },
                    transition: "0.5s",
                    backgroundColor: "rgba(0, 0, 0, 0.22)",
                    color: "white",
                    minHeight: "fit-content",
                    maxWidth: 400,
                    mt: 15,
                  }}
                  disableRipple
                  key={story.id}
                >
                  <Stack
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 10,
                    }}
                  >
                    <CardContent>
                      <Typography
                        gutterBottom
                        variant="h5"
                        fontWeight="700"
                        letterSpacing="4px"
                        fontSize={{
                          md: i === isActive ? "46px" : "42px",
                          xs: "22px",
                        }}
                      >
                        {story?.year}
                      </Typography>
                      <Typography
                        variant="body2"
                        fontSize={{
                          md: i === isActive ? "30px" : "27px",
                          xs: "22px",
                        }}
                        sx={{
                          px: "10px",
                        }}
                        letterSpacing="1px"
                        fontWeight="500"
                        color="rgba(255, 255, 255, 0.70)"
                      >
                        {story?.description}
                      </Typography>
                    </CardContent>
                    <CardMedia
                      component="img"
                      height="250"
                      width={"100%"}
                      image={story?.image[0]?.image_url}
                      alt={story.alt}
                    />
                  </Stack>
                </Card>
              ))}
            </CustomSlider>
          </Box>
        </CustomFlexContainer>
      </WrappingContainer>
    </Box>
  );
}
