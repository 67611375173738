/* eslint-disable array-callback-return */
import Types from "./Types";

const INIT_STATE = {
  contactUsFormData: {},
  newsLetterForm: {},
  careersForm: {},
  error: false,
  isLoading: {
    type: "",
    loading: false,
  },
};

export default function contactUsFormReducer(state = INIT_STATE, action) {
  const { type, payload } = action;
  switch (type) {
    case Types.FORM_LOADING: {
      return {
        ...state,
        isLoading: payload,
      };
    }
    case Types.CONTACT_US_PAGE_DATA_SUCCESS: {
      return {
        ...state,
        contactUsFormData: payload?.result,
      };
    }
    case Types.NEWS_LETTER_FORM_SUCCESS: {
      return {
        ...state,
        newsLetterForm: payload?.result,
      };
    }
    case Types.CAREERS_FORM_SUCCESS: {
      return {
        ...state,
        careersForm: payload?.result,
      };
    }
    case Types.FAIL_NEWSLETER: {
      console.log(payload?.result ,'sssssssssssssssssssss');
      return {
        ...state,
        error: payload?.result?.error,
      };
    }
    default: {
      return state;
    }
  }
}
