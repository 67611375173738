import { Box, Stack, Typography } from "@mui/material";
import { images } from "assets/AssetHelper";
import CustomFlexContainer from "components/Atoms/Containers/CustomFlexContainer";
import AppSlider from "components/Molecules/Slider/AppSlider";
import { NextArrow, PrevArrow } from "components/Molecules/Slider/SliderArrows";
import ArrowLeftOutlinedIcon from "@mui/icons-material/ArrowLeftOutlined";
import ArrowRightOutlinedIcon from "@mui/icons-material/ArrowRightOutlined";
import MetallicBar from "components/Molecules/Home/MetallicBar";

export default function Story({ aboutPageData, homePageData }) {
  const sliderItems = () => {
    let items = aboutPageData?.slider?.map((imgObj, i) => (
      <>
        <Box
          key={i}
          component={"img"}
          src={imgObj?.filter((img) => img?.size == 1920)[0]?.image_url}
          sx={{
            height: { lg: "100%", md: "400px", xs: "250px" },
            display: "block",
            mb: "-10px",
            width: "100%",
          }}
        />
      </>
    ));
    return items;
  };

  return (
    <>
      <Box>
        <AppSlider
          className="home-slider"
          items={sliderItems()}
          slidesToShow={{ xs: 1, sm: 1, md: 1, lg: 1, xl: 1 }}
          centerMode={false}
          autoplay={true}
          fade={true}
          nextArrow={
            <NextArrow
              sx={{
                right: "0px",
                fontSize: "100px",
                color: "#DAD9D7",
              }}
              arrowIcon={ArrowRightOutlinedIcon}
            />
          }
          prevArrow={
            <PrevArrow
              sx={{
                left: "0px",
                fontSize: "100px",
                color: "#DAD9D7",
              }}
              arrowIcon={ArrowLeftOutlinedIcon}
            />
          }
        />
      </Box>

      {/* <Box
        sx={{
          position: "relative",
        }}
      >
        <Box
          sx={{
            backgroundImage: `url(${images.metallicBar})`,
            backgroundSize: "100% 100%",
            width: { xs: "85%", sm: "55%", md: "60%" },
            position: "absolute",
            left: "50%",
            // pt: { lg: "2rem" },
            pb: { lg: "2rem" },
            transform: "translate(-50%, -42%)",
          }}
        >
          <CustomFlexContainer
            sx={{
              mx: "auto",
              // height: "120px",
              justifyContent: "space-between",
              // width: { xs: "90%", md: "80%" },
            }}
          >
            <Stack justifyContent="center" alignItems="center" px="2rem">
              <Typography
                sx={{
                  fontSize: "50px",
                  color: "#48484C",
                  textTransform: "uppercase",
                  my: "1rem",
                }}
              >
                About Us
              </Typography>
              <Typography sx={{ textAlign: "center", fontWeight: 500 }}>
                Fusteka Group was established in 2000 in Basra city, Iraq. Our
                group accomplished and offered many projects and services in the
                fields of ice cream production & retail sales, frozen food,
                construction and supply.
              </Typography>
            </Stack>
          </CustomFlexContainer>
        </Box>
      </Box> */}
    </>
  );
}
