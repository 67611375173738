import { reduxRequest } from "../utils/reduxRequest";
import { HomeDataLoading, HomeDataSuccess } from "./Actions";

export const homePageDataRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: HomeDataLoading,
    successFunction: HomeDataSuccess,
    url: "/group/home",
    method: "get",
    ...variables,
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};
