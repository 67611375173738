import { Box, Stack, Typography, useMediaQuery } from "@mui/material";
import CustomButton from "components/Atoms/Buttons/CustomButton";

import CustomFlexContainer from "components/Atoms/Containers/CustomFlexContainer";
import WrappingContainer from "components/Atoms/Containers/WrappingContainer";
import MemberCard from "components/Molecules/About/MemberCard";
import { members } from "data";
import { useEffect, useRef, useState } from "react";

export default function Members({ boardMembers }) {
  const isMdScreen = useMediaQuery("(min-width: 900px)"); // Change the breakpoint as needed
  const initialMembersToShow = isMdScreen ? 4 : 2;
  let [memberToShow, setMemberToShow] = useState(initialMembersToShow);

  const [length, setLength] = useState();

  useEffect(() => {
    setLength(memberToShow);
  }, [memberToShow]);

  return (
    <CustomFlexContainer gap={15} col sx={{ py: 35 }}>
      <Typography
        sx={{
          color: "#202249",
          fontWeight: "900",
          fontSize: { md: "50px", sm: "30px", xs: "19px" },
          letterSpacing: "4px",
          textTransform: "uppercase",
        }}
      >
        Our Board Members
      </Typography>
      {/* loop over members and create cards */}
      <WrappingContainer>
        <CustomFlexContainer
          sx={{
            flexWrap: "wrap",
            justifyContent: { lg: "space-between", xs: "center" },
            gap: "2%",
          }}
        >
          {boardMembers
            ?.map((member,i) => (
              <MemberCard
                width={{ md: "22%", sm: "45%", xs: "48%" }}
                delay={(i + 0.1) * 100}
                member={member}
                key={member.id}
              />
            ))
            .slice(0, memberToShow)}
        </CustomFlexContainer>
        <Stack
          alignItems="center"
          justifyContent="center"
          sx={{
            display: memberToShow < boardMembers?.length ? "block" : "none",
          }}
        >
          <CustomButton
            onClick={(_) => setMemberToShow(memberToShow + 2)}
            wrapperStyle={{ mx: "auto" }}
          >
            SHOW MORE
          </CustomButton>
        </Stack>
      </WrappingContainer>
    </CustomFlexContainer>
  );
}
