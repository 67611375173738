import { Box, Container } from "@mui/material";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { NextArrow, PrevArrow } from "./SliderArrows";
const breakPoints = {
  xs: 425,
  sm: 660,
  md: 900,
  lg: 1200,
  xl: 1536,
};

export default function AppSlider({
  items,
  width = "98%",
  slidesToShow,
  setActiveSlides,
  nextArrow = <NextArrow />,
  prevArrow = <PrevArrow />,
  centerMode = true,
  arrows = true,
  rows = 1,
  infinite = true,
  xsInfinite,
  className,
  initialSlide = 0,
  autoplay = false,
  fade=false
}) {
  const { xs, sm, md, lg, xl } = slidesToShow;

  const settings = {
    className: "center",
    centerMode,
    slidesToShow: xl,
    slidesToScroll: 1,
    infinite: infinite,
    speed: 1000,
    nextArrow,
    prevArrow,
    arrows,
    initialSlide: initialSlide,
    rows: rows,
    slidesPerRow: rows ? 1 : 0,
    autoplay: autoplay,
    pauseOnFocus: false,
    fade:fade,
    // slidesPerRow: 2,
    //to track the index of the next centered item to apply styles
    beforeChange: (current, next) =>
      setActiveSlides && setActiveSlides({ activeSlide1: next }),
    afterChange: (current) =>
      setActiveSlides && setActiveSlides({ activeSlide2: current }),
    responsive: [
      {
        breakpoint: breakPoints.xl,
        settings: {
          slidesToShow: xl,
        },
      },
      {
        breakpoint: breakPoints.lg,
        settings: {
          slidesToShow: lg,
          rows: 1
        },
      },
      {
        breakpoint: breakPoints.md,
        settings: {
          slidesToShow: md,
          rows: 1
        },
      },
      {
        breakpoint: breakPoints.sm,
        settings: {
          slidesToShow: sm,
          rows: 1
        },
      },
      {
        breakpoint: breakPoints.xs,
        settings: {
          slidesToShow: sm,
          infinite: xsInfinite,
          rows: 1
        },
      },
    ],
  };
  return <Box className={className && className}>
    <Slider  {...settings}>{items}</Slider>
  </Box>
}
