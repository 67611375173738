import { reduxRequest } from "../utils/reduxRequest";
import { careersFormSuccess, formFormSuccess, formLoading, newsLetterFormFail, newsLetterFormSuccess } from "./Actions";

export const contactUsFormRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: formLoading,
    successFunction: formFormSuccess,
    url: "/group/forms/sales",
    method: "post",
    ...variables,
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const newsLetterFormRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: formLoading,
    successFunction: newsLetterFormSuccess,
    failFunction: newsLetterFormFail,
    url: "/group/newsletter/post",
    method: "post",
    ...variables,
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const careersFormRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: formLoading,
    successFunction: careersFormSuccess,
    url: "/group/forms/hr",
    method: "post",
    ...variables,
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};
