import { Box, Stack } from "@mui/material";
import { icons, images, videos } from "assets/AssetHelper";
import WrappingContainer from "components/Atoms/Containers/WrappingContainer";
import React, { useRef, useState } from "react";

export default function HomePageVideo({ video }) {
  const [isPlayed, setIsPlayed] = useState(false);
  const videoRef = useRef();
  // const playVideo = () => {
  //   videoRef?.current?.play();
  // };
  return (
    <>
      <Box
        sx={{
          backgroundImage: `url(${images.gradientBackground})`,
          backgroundPosition: "center",
          backgroundSize: "100% 100%",
          backgroundRepeat: "no-repeat",
          pt: 40,
          mb: { lg: "100px", md: "80px", xs: "70px" },
        }}
      >
        <WrappingContainer
          sx={{
            textAlign: "center",
            minHeight: "45vh",
          }}
        >
          <Stack
            sx={{
              py: {
                xl: "5rem",
                lg: "4rem",
                md: "3rem",
                xs: "2rem",
              },
            }}
          >
            <Box
              width={"100%"}
              sx={{
                position: "relative",
              }}
            >
              <Box sx={{ borderRadius: "20px" }}>
                {video && (
                  <video
                    width="100%"
                    style={{ borderRadius: "20px" }}
                    ref={videoRef}
                    muted
                    autoPlay
                    loop
                  >
                    <source src={video} />
                  </video>
                )}
              </Box>
              {/* {!isPlayed && (
                <Stack
                  justifyContent="center"
                  alignItems="center"
                  sx={{
                    position: "absolute",
                    bgcolor: "#10101059",
                    top: 0,
                    bottom: 0,
                    right: 0,
                    left: 0,
                    borderRadius: "20px",
                    zIndex: 10000
                  }}
                >
                  <Box
                    component="img"
                    src={icons.playIcon}
                    alt="play icon"
                    sx={{
                      cursor: "pointer",
                    }}
                    onClick={(_) => {
                      setIsPlayed(true);
                      playVideo();
                    }}
                  />
                </Stack>
              )} */}
            </Box>
          </Stack>
        </WrappingContainer>
      </Box>
    </>
  );
}
