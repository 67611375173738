import { Box, Button, Grid, Typography } from "@mui/material";
import cvSectionImage from "../../../assets/images/career/group-of-people.png";
import mobileImg from "../../../assets/images/career/mobileImg.svg";
import AppFormField from "components/Atoms/Form/AppFormField";
import SubmitButton from "components/Atoms/Form/SubmitButton";
import { getPresignedUrlRequest } from "services/modules/uploadFiles";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { careersFormRequest } from "services/modules/Forms";
import * as Yup from "yup";
import { createPortal } from "react-dom";
import Modal from "components/Atoms/Model/Model";
import Animation from "components/Atoms/SharedComponent/Animation";

export default function ApplicationForm({
  educationDegreesData,
  jobPositionsData,
}) {
  const [fileName, setFileName] = useState("");
  const dispatch = useDispatch();
  let {
    files,
    load: { loading },
  } = useSelector((state) => state?.uploadFile);
  const [modalData, setModalData] = useState({
    title: "Thank you for applying to Fusteka",
    type: "message",
  });
  //modal
  const imgtargetElementRef = useRef(null);
  const formTargetElementRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const handleOpen = () => setIsOpen(true);

  const handleClose = () => {
    setIsOpen(false);
  };

  const initialValues = {
    name: "",
    current_position: "",
    lastEducationDegree: null,
    positionApplyFor: null,
    last_job_start: "",
    last_job_end: "",
    cv_url: "",
  };

  const ApplicationFormFields = [
    {
      label: "Name",
      name: "name",
      type: "text",
      placeholder: "Enter your name ",
    },
    {
      label: "Current position",
      name: "current_position",
      type: "text",
      placeholder: "Type your current position",
    },
    {
      label: "Last education degree",
      name: "lastEducationDegree",
      type: "dropdown",
      placeholder: "Choose from the list ...",
      options: educationDegreesData,
    },
    {
      label: "Position apply for",
      name: "positionApplyFor",
      type: "dropdown",
      placeholder: "Choose from the list ...",
      options: jobPositionsData,
    },
    {
      label: "Last job duration",
      name: "last_job_start",
      type: "date",
      placeholder: "From",
    },
    {
      label: "", //left intentionally empty
      name: "last_job_end",
      type: "date",
      placeholder: "To",
    },
    {
      label: "Upload your CV",
      name: "cv_url",
      type: "file",
      placeholder: "Choose a file...",
    },
  ];

  const requiredFieldErr = "This field is required";
  const validationSchema = Yup.object().shape({
    name: Yup.string().required(requiredFieldErr).label("Name"),
    current_position: Yup.string().required(requiredFieldErr),
    lastEducationDegree: Yup.object() // Use Yup.object() to validate objects
      .nullable()
      .test("required", "This field is required", function (value) {
        return value !== null;
      })
      .shape({
        label: Yup.string().required("This field is required"),
        value: Yup.string().required("This field is required"),
      }),
    positionApplyFor: Yup.object() // Use Yup.object() to validate objects
      .nullable()
      .test("required", "This field is required", function (value) {
        return value !== null;
      })
      .shape({
        label: Yup.string().required("This field is required"),
        value: Yup.string().required("This field is required"),
      }),
    last_job_start: Yup.date()
      .required(requiredFieldErr)
      .label("Last job duration"),
    last_job_end: Yup.date()
      .required(requiredFieldErr)
      .min(Yup.ref("last_job_start"), "End date can't be before start date"),
    cv_url: Yup.string().required(requiredFieldErr),
  });

  const formStyle = {
    bgcolor: { md: "#1B2853", xs: "#172549" },
    py: 15,
    px: 10,
    display: "flex",
    flexWrap: "wrap",
    borderRadius: "8px",
    justifyContent: "space-between",
    gap: 9,
  };

  const onSubmit = (values, { setSubmitting }) => {
    values = {
      ...values,
      lastEducationDegree: values?.lastEducationDegree?.value,
      positionApplyFor: values?.positionApplyFor?.value,
      cv_url: files,
    };
    const requestData = {
      body: { jsonrpc: "2.0", params: { ...values } },
      action: handleOpen(),
    };
    files && dispatch(careersFormRequest({ ...requestData }));
  };

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit,
    validationSchema: validationSchema,
  });

  const uploadFile = (e) => {
    console.log(e, "sssssssssssssssss");
    if (
      e?.target?.files[0]?.type?.slice(
        e?.target?.files[0]?.type?.indexOf("/") + 1
      ) != "pdf"
    ) {
      setIsOpen(true);
      setModalData({ title: "File must be pdf", type: "error" });
      return;
    }
    setModalData({
      title: "Thank you for applying to Fusteka",
      type: "message",
    });
    dispatch(
      getPresignedUrlRequest({
        body: {
          file: e.target.files[0],
          params: {
            extension: e.target.files[0]?.name.substring(
              e.target.files[0]?.name.lastIndexOf(".") + 1,
              e.target.files[0]?.name?.length
            ),
          },
        },
        action: (data) => {
          console.log(data, "daaataa");
          formik.setValues({ ...formik?.values, cv_url: data });
          setFileName(e?.target?.files[0]?.name || "");
        },
      })
    );
  };
  console.log(files);

  return (
    <>
      {createPortal(
        <Modal
          openVar={isOpen}
          closeFunc={handleClose}
          title={modalData?.title}
          titleStyle={{
            color: modalData?.type == "message" ? "#1A71AB" : "red",
            fontSize: { md: "36px", sm: "25px", xs: "20px" },
            fontWeight: 600,
          }}
        >
          {modalData?.type == "message" && (
            <>
              <Typography
                id="modal-modal-description"
                sx={{ mt: 2, fontSize: { md: "32px", sm: "20px", xs: "15px" } }}
              >
                We have Received your resume and appreciate your interest in
                joining our team ,we will be in touch with you soon
              </Typography>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleClose}
                sx={{
                  width: "fit-content",
                  color: "white",
                  m: "0 auto",
                  fontWeight: "700",
                }}
              >
                BACK
              </Button>
            </>
          )}
        </Modal>,
        document.body
      )}

      <>
        <Grid container justifyContent="center" sx={{ py: { md: 30 } }}>
          <Animation
            targetElementRef={imgtargetElementRef}
            className={"move"}
            delay={300}
          />
          <Grid item lg={6} xs={11} sx={{ display: "flex" }}>
            <Box
              component="img"
              loading="lazy"
              src={cvSectionImage}
              alt="Group Of Employees"
              width={{ lg: "95%", xs: "100%" }}
              sx={{
                maxHeight: "1000px",
                minHeight: "100%",
                margin: "0 auto",
                display: { lg: "inline-block", xs: "none" },
              }}
              ref={imgtargetElementRef}
              className="moving-from-left"
            />
            <Box
              component="img"
              loading="lazy"
              src={mobileImg}
              alt="Group Of Employees"
              width={{ lg: "95%", xs: "100%" }}
              sx={{
                maxHeight: "1000px",
                minHeight: "100%",
                margin: "0 auto",
                display: { lg: "none", xs: "inline-block" },
              }}
            />
          </Grid>

          {/* Form */}
          <Animation
            targetElementRef={formTargetElementRef}
            className={"move"}
            delay={300}
          />
          <Grid item lg={6} xs={12}>
            <Typography
              color="secondary.sub"
              fontSize={{ md: "28px", sm: "19px", xs: "15px" }}
              sx={{ mb: 10, mt: { lg: 0, xs: 15 } }}
            >
              Or Send us your CV
            </Typography>

            <Box
              component={"form"}
              sx={formStyle}
              onSubmit={formik.handleSubmit}
              className="moving-from-right"
              ref={formTargetElementRef}
            >
              {ApplicationFormFields.map((field, i) => (
                <AppFormField
                  uploadFile={uploadFile}
                  key={i}
                  field={field}
                  formik={formik}
                  width={field.type === "date" ? "46%" : "100%"}
                  inputSx={{ backgroundColor: "#fff", color: "#fff" }}
                  values={initialValues}
                  fileName={fileName}
                  uploadLoading={loading}
                  id={"0"}
                />
              ))}
              <Box sx={{ py: "20px" }}>
                <SubmitButton>SEND</SubmitButton>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </>
    </>
  );
}
