import { Box, Stack, Typography } from "@mui/material";
import { images } from "assets/AssetHelper";
import CustomButton from "components/Atoms/Buttons/CustomButton";
import CustomFlexContainer from "components/Atoms/Containers/CustomFlexContainer";
import WrappingContainer from "components/Atoms/Containers/WrappingContainer";
import SectionHeader from "components/Atoms/Text/SectionHeader";
import NewsCardData from "components/Molecules/News/NewsCardData";
import React from "react";
import { useNavigate } from "react-router-dom";
import * as dayjs from "dayjs";

export default function NewsDetailsTemplate({ newsDetailsData, newsPageData }) {
  const navigate = useNavigate();
  console.log(newsDetailsData, "newsDetailsData");
  return (
    <>
      <Box
        sx={{
          backgroundImage:
            "linear-gradient(95.99deg, #D5242F -25.84%, #1F366A 4.13%, #151127 121.71%)",
          py: 40,
        }}
      >
        <WrappingContainer>
          <CustomFlexContainer col sx={{ alignItems: "start", gap: 30 }}>
            <Stack
              direction="row"
              flexWrap="wrap"
              rowGap="5rem"
              justifyContent="space-between"
              // alignItems="stretch"
            >
              <Stack
                width="100%"
                rowGap="16px"
                justifyContent="center"
                alignItems="center"
              >
                <Typography
                  variant="h5"
                  color={"white"}
                  fontSize={{ md: "20px", xs: "14px" }}
                >
                  {dayjs(newsDetailsData?.date).format("DD MMM YYYY")}
                </Typography>
                <SectionHeader
                  sx={{ textTransform: "none", fontSize: "46px", mb: "2.5rem" }}
                >
                  {newsDetailsData?.title}
                </SectionHeader>
                <picture
                  width="100%"
                  style={{ marginBottom: "5rem", width: "100%" }}
                >
                  {/* {newsDetailsData?.image?.slice(0, 3)?.map((img) => (
                    <source
                      style={{ width: "100%", height: "100%" }}
                      key={img?.size}
                      media={`(min-width:${img?.size}px)`}
                      srcSet={img?.image_url}
                    />
                  ))} */}
                  <Box
                    component="img"
                    width={"100%"}
                    height={"100%"}
                    src={newsDetailsData?.details_image}
                    alt=""
                  />
                </picture>
                <Typography
                  // variant="h5"
                  color={"white"}
                  fontSize={{ md: "24px", xs: "14px" }}
                  textAlign="center"
                  dangerouslySetInnerHTML={{ __html: newsDetailsData.article }}
                />
                <Stack mt="100px">
                  <SectionHeader>MORE NEWS</SectionHeader>
                </Stack>
              </Stack>

              {newsPageData
                ?.filter((data) => data?.id != newsDetailsData?.id)
                ?.map((data, index) => (
                  <Stack
                    rowGap="16px"
                    direction="row"
                    sx={{ bgcolor: "white", width: "49%", cursor: "pointer" }}
                    onClick={(_) => navigate(`/news-details/${data?.id}`)}
                  >
                    <Box sx={{ width: { md: "30%", xs: "100%" } }}>
                      <Box
                        width="100%"
                        component="img"
                        height={"100%"}
                        src={
                          data?.image?.filter((img) => img?.size == 1920)[0]
                            ?.image_url
                        }
                      />
                    </Box>
                    <Stack
                      sx={{
                        p: "2rem",
                        width: { md: "70%", xs: "100%" },
                      }}
                      // height={"100%"}
                    >
                      <NewsCardData data={data} isMain={false} />
                    </Stack>
                  </Stack>
                ))
                .slice(0, 4)}
            </Stack>
            <Stack width="100%" justifyContent="center" alignItems="center">
              <Box
                sx={{
                  mt: "30px",
                  display: "inline",
                  mx: { sm: 0, xs: "auto" },
                }}
              >
                <CustomButton onClick={(_) => navigate("/latest-news")}>
                  SHOW MORE
                </CustomButton>
              </Box>
            </Stack>
          </CustomFlexContainer>
        </WrappingContainer>
      </Box>
    </>
  );
}
