import { Box } from '@mui/material';
import AboutCompany from 'components/Molecules/Brands/AboutCompany';
import BrandHeader from 'components/Molecules/Brands/BrandHeader';
import { brands } from 'data';

const { logo, brandHeaderImage, name, about, url, info, products } = brands['virtualWorld'];
const brand = { logo, brandHeaderImage, name, about, url };

export default function VirtualWorldGroup() {
   return (
      <Box>
         <BrandHeader brand={brand} />
         <AboutCompany
            info={info}
            body=" Virtual world group is a group of software solutions, E-commerce & Logistics,
                  First company interested in such fields in Iraq"
         />
      </Box>
   );
}
