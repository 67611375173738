import { Button } from '@mui/material';

export default function SubmitButton({ children = 'SUBMIT' }) {

   return (
      <Button
         type="submit"
         color="secondary"
         variant="contained"
         sx={{
            color: 'white',
            fontSize: { md: '24px', sm: '20px', xs: '14px' },
            fontWeight: '600',
            borderRadius: '0',
            width: 'fit-content',
            py: { md: 5 , xs:3},
            px: { md: 30, xs: 20 },
         }}
      // disabled={isSubmitting}
      >
         {/* {isSubmitting ? <Loader /> : <>{children}</>} */}
         <>{children}</>
      </Button>
   );
}
