import { Box, Typography, Button, Stack } from '@mui/material';
import WrappingContainer from 'components/Atoms/Containers/WrappingContainer';
import ErrorMessage from 'components/Atoms/Form/ErrorMessage';
import TextFieldInput from 'components/Atoms/Input/TextField/TextFieldInput';
import { useFormik } from 'formik';
import { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { newsLetterFormRequest } from 'services/modules/Forms';
import * as Yup from 'yup';

export default function GetUpdatedForm() {
   const dispatch = useDispatch()
   const [success, setSuccess] = useState(false);

   const successFun = () => {
      setSuccess(true);
      setTimeout(() => {
         setSuccess(false);
      }, 2000);
   }

   const onSubmit = async (values) => {
      const requestData = {
         body: { jsonrpc: "2.0", params: { email: values?.email } },
         action: successFun(true)
      };
      dispatch(newsLetterFormRequest({ ...requestData }))
   };
   const requiredFieldErr = 'This field is required';
   const emailRegex = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

   const validationSchema =
      Yup.object().shape({
         email: Yup.string().required(requiredFieldErr).matches(emailRegex, 'Email is not valid'),
      });

   const initialValues = {
      email: "",
   };

   const formik = useFormik({
      initialValues,
      onSubmit,
      validationSchema: validationSchema,
   });

   return (
      <>
         <Box component="section">
            <form onSubmit={formik?.handleSubmit}>
               <WrappingContainer
                  sx={{
                     display: 'flex',
                     flexDirection: 'column',
                     alignItems: 'center',
                     py: { md: 35, sm: 20, xs: 15 },
                     gap: { md: 20, sm: 10, xs: 8 },
                     textAlign: 'center',
                  }}
               >
                  <Typography
                     variant="h2"
                     color="secondary"
                     sx={{ fontFamily: 'Poppins', fontWeight: '600', fontSize: { md: '30px', sm: '20px', xs: '12px' } }}
                  >
                     GET THE NEWSLETTER
                  </Typography>
                  <Typography
                     variant="h2"
                     color="primary"
                     sx={{ fontFamily: 'Poppins', fontWeight: '700', fontSize: { md: '50px', sm: '30px', xs: '19px' } }}
                  >
                     Wants to get updated?
                  </Typography>
                  <Stack
                     sx={{
                        flexDirection: 'row',
                        justifyContent: { xs: 'center', md: 'space-between' },
                        width: { xs: '100%', sm: '70%', md: '70%', lg: '50%' },
                        gap: { md: 20 },
                     }}
                  >
                     <Box sx={{ width: '100%' }} >
                        <TextFieldInput
                           placeholder="Enter your Email address"
                           sx={{
                              width: '100%',
                              '& input': {
                                 p: { md: '16.5px 14px !important', xs: '10px 14px!important' }
                              }
                           }}
                           value={formik?.values?.email}
                           name='email'
                           error={formik?.errors?.email && formik?.touched?.email}
                           errorMessage={formik?.errors?.email}
                           onChange={formik?.handleChange}
                        // clickEnter={handleSubmit}
                        />
                        {success && <Typography sx={{ color: 'green', textAlign: 'start' }} >
                           We received your email successfully
                        </Typography>}
                     </Box>
                     <Button
                        // onClick={_ => setIsOpen(true)}
                        variant="contained"
                        type='submit'
                        sx={{
                           backgroundColor: 'secondary.light',
                           '&:hover': { backgroundColor: 'secondary.light' },
                           px: { md: 35, xs: '18px' },
                           borderRadius: 0,
                           color: 'white',
                           fontFamily: 'Poppins',
                           height: '56px',
                           fontWeight: '500',
                           ml: '-3px',
                           fontSize: { md: '24px', sm: '16px', xs: '14px' },
                        }}
                     >
                        Subscribe
                     </Button>
                  </Stack>
                  {/* {success &&  */}


                  {/* } */}
               </WrappingContainer >
            </form>
            {/* contact us */}
         </Box >
      </>
   );
}
