import React from "react";
import GoogleMapReact from "google-map-react";
import { Box } from "@mui/material";

const Map = ({ text }) => <div>{text}</div>;

export default function Location() {
  const defaultProps = {
    center: {
      lat: 10.99835602,
      lng: 77.01502627,
    },
    zoom: 11,
  };

  return (
    // Important! Always set the container height explicitly
    <Box sx={{ height: { xs: "30vh", md: "100%" }, width: "100%" }}>
      {/* <GoogleMapReact
            bootstrapURLKeys={{ key: 'AIzaSyDhWYESSsqELza6S-sg3UdMN4hze8oSJ6w' }}
            defaultCenter={defaultProps.center}
            defaultZoom={defaultProps.zoom}
         >
            <Map lat={59.955413} lng={30.337844} text="My Marker" />
         </GoogleMapReact> */}
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3438.5699410287743!2d47.788146!3d30.476617!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMzDCsDI4JzM1LjgiTiA0N8KwNDcnMTcuMyJF!5e0!3m2!1sen!2seg!4v1703762251804!5m2!1sen!2seg"
        width="100%"
        height="100%"
        allowfullscreen=""
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
        title='fustuka group location'
      ></iframe>
    </Box>
  );
}

// AIzaSyDhWYESSsqELza6S-sg3UdMN4hze8oSJ6w
