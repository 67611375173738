import { reduxRequest } from "../utils/reduxRequest";
import { AboutDataLoading, AboutDataSuccess } from "./Actions";

export const aboutPageDataRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: AboutDataLoading,
    successFunction: AboutDataSuccess,
    url: "/group/about",
    method: "get",
    ...variables,
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};
