import { Box } from '@mui/material';
import BrandHeader from 'components/Molecules/Brands/BrandHeader';
import ProductsPortfolio from 'components/Molecules/Brands/ProductsPortfolio';
import { brands } from 'data';

const { logo, brandHeaderImage, name, about, url, info, products } = brands['jekor'];
const brand = { logo, brandHeaderImage, name, about, url };

export default function BrandsDetailsTemplate({ brandsDetailsData }) {
   return (
      <Box>
         <BrandHeader brandsDetailsData={brandsDetailsData}  brand={brand} />
         <ProductsPortfolio  productsData={brandsDetailsData?.product_data} products={products} />
      </Box>
   );
}
