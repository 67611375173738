import { Box, Link } from '@mui/material';
import CustomFlexContainer from 'components/Atoms/Containers/CustomFlexContainer';

export default function SocialMedia({ socialMediaIcons }) {
   return (
      <CustomFlexContainer gap={10}>
         {socialMediaIcons.map((platform) => (
            <Link key={platform.id} href={platform.url}>
               <Box
                  component='img'
                  width={{ md: '48px', xs: '32px' }}
                  height={{ md: '48px', xs: '32px' }}
                  src={platform.icon}
                  alt={platform.alt}
                  loading="lazy"
               />
            </Link>
         ))}
      </CustomFlexContainer>
   );
}
