import { Box } from "@mui/material";
import AppSlider from "components/Molecules/Slider/AppSlider";
import { NextArrow, PrevArrow } from "components/Molecules/Slider/SliderArrows";
import ArrowLeftOutlinedIcon from "@mui/icons-material/ArrowLeftOutlined";
import ArrowRightOutlinedIcon from "@mui/icons-material/ArrowRightOutlined";

export default function HomeHeader({ headerData }) {
  const sliderItems = () => {
    let items = headerData?.slider?.map((imgObj, i) => (
      <>
        <Box
          key={i}
          component={"img"}
          src={imgObj?.filter((img) => img?.size == 1920)[0]?.image_url}
          sx={{
            height: { lg: "100%", md: "400px", xs: "250px" },
            display: "block",
            mb: "-10px",
            width: "100%",
          }}
        />
      </>
    ));
    return items;
  };

  return (
    <Box>
      <AppSlider
        items={sliderItems()}
        slidesToShow={{ xs: 1, sm: 1, md: 1, lg: 1, xl: 1 }}
        className="home-slider"
        centerMode={false}
        autoplay={true}
        fade={true}
        nextArrow={
          <NextArrow
            sx={{
              right: "0px",
              fontSize: "100px",
              color: "rgba(236, 236, 236,0.7)",
            }}
            arrowIcon={ArrowRightOutlinedIcon}
          />
        }
        prevArrow={
          <PrevArrow
            sx={{
              left: "0px",
              fontSize: "100px",
              color: "rgba(236, 236, 236,0.7)",
            }}
            arrowIcon={ArrowLeftOutlinedIcon}
          />
        }
      />
    </Box>
  );
}
