import { Box } from '@mui/material';
import ErrorMessage from './ErrorMessage';
import CustomFlexContainer from '../Containers/CustomFlexContainer';
import CustomInput from './CustomInput';

export default function AppFormField({ labelSx, inputSx, field, width = '100%', uploadFile, values, formik, fileName, uploadLoading ,id}) {
   const { label, name, type = 'text', placeholder, ...rest } = field;


   const inputStyle = {
      py: { md: 7, xs: 3 },
      px: 5,
      backgroundColor: '#fff',
      fontSize: '20px',
      borderRadius: '6px',
      ...inputSx,
   };
   console.log(fileName);
   return (
      <CustomFlexContainer col sx={{ alignItems: 'stretch', width: { width } }} gap={10}>
         <Box component="label" sx={{ fontSize: { md: '20px', sm: '18px', xs: '13px' }, color: 'white', ...labelSx }}>
            {label}
         </Box>
         <Box sx={{ mt: 'auto' }}>
            <CustomInput
               disableUnderline
               onBlur={() => formik?.setFieldTouched(name)}
               onChange={formik?.handleChange(name)}
               setFieldValue={formik?.setFieldValue}
               sx={inputStyle}
               type={type}
               placeholder={placeholder}
               field={field}
               uploadFile={uploadFile}
               values={values}
               formik={formik}
               name={name}
               fileName={fileName}
               uploadLoading={uploadLoading}
               id={id}
               {...rest}
            />
         </Box>
         <ErrorMessage error={formik?.errors[name]} visible={formik?.touched[name]} />
      </CustomFlexContainer >
   );
}
