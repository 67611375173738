import { Box, Grid, Stack, Typography } from "@mui/material";
import CustomFlexContainer from "components/Atoms/Containers/CustomFlexContainer";
import WrappingContainer from "components/Atoms/Containers/WrappingContainer";
import SectionHeader from "components/Atoms/Text/SectionHeader";
import { useState } from "react";
import CustomSlider from "../Slider/CustomSlider";

export default function ProductsPortfolio({ products, productsData }) {
  const [isActive, setIsActive] = useState(1);
  const [isCurrent, setIsCurrent] = useState();
  return (
    <Box
      sx={{
        background:
          "linear-gradient(145deg, #D5242F -55%, #1F366A 20.31%, #151127 100%)",
      }}
    >
      <WrappingContainer sx={{ py: { md: 40, xs: 20 }, textAlign: "center" }}>
        <CustomFlexContainer col gap={{ md: 15, xs: 4 }}>
          <SectionHeader sx={{ m: 0 }}>Products Portfolio</SectionHeader>

          <CustomFlexContainer
            gap={15}
            sx={{
              minWidth: { md: "95%", xs: "100%" },
              maxWidth: { md: "90%", xs: "100%" },
              flexWrap: "wrap",
              justifyContent: "center",
            }}
          >
            <Typography
              variant="body1"
              color="white"
              sx={{
                fontSize: { md: "23px", sm: "20px", xs: "16px" },
                fontWeight: "400",
                lineHeight: { md: "42px" },
                width: { md: "80%" },
              }}
            >
              {productsData?.description}
            </Typography>
            <Grid
              container
              sx={{
                display: "flex",
                justifyContent: "center",
                gap: { xs: 10, md: 35 },
              }}
            >
              <Box className="brand-details-slider" sx={{ width: "98%" }}>
                <CustomSlider
                  slidesToShowXl={2}
                  setIsActive={setIsActive}
                  length={productsData?.length - 1}
                  className="brands-details"
                  xsInfinite={productsData?.product?.length > 1 ? true : false}
                  rows={productsData?.products?.length > 2 ? 2 : 1}
                  infinite={productsData?.products?.length > 4 ? true : false}
                  initialSlide={1}
                  maxWidth="xl"
                  containerPadding="0 !important"
                  slidesToShowMd={2}
                  setIsCurrent={setIsCurrent}
                >
                  {productsData?.products?.map((product, i) => (
                    <Stack
                      rowGap={"50px"}
                      sx={{
                        width: { md: "80% !important", xs: "85% !important" },
                      }}
                      key={i}
                    >
                      <Grid item key={product.id} xl={12} md={12} xs={12}>
                        <picture>
                          {product?.image?.slice(0, 3)?.map((img) => (
                            <source
                              style={{ width: "100%", borderRadius: "10px" }}
                              key={img?.size}
                              media={`(min-width:${img?.size}px)`}
                              srcSet={img?.image_url}
                            />
                          ))}
                          <Box
                            component="img"
                            width={"100%"}
                            style={{ borderRadius: "10px" }}
                            src={
                              product?.image?.filter(
                                (img) => img?.size == 1920
                              )[0]?.image_url
                            }
                            alt=""
                          />
                        </picture>
                        <Typography
                          textTransform="uppercase"
                          color="white"
                          mb="30px"
                          fontSize={{ lg: "50px" }}
                          fontWeight={"700"}
                          mt={10}
                        >
                          {product?.title}
                        </Typography>
                      </Grid>
                    </Stack>
                  ))}
                </CustomSlider>
              </Box>
            </Grid>
          </CustomFlexContainer>
        </CustomFlexContainer>
      </WrappingContainer>
    </Box>
  );
}
