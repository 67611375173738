import { Box } from "@mui/material";

export default function Logo({ src, alt, sx, borderRadius, backgroundColor }) {
  return (
    <Box
      sx={{
        borderRadius: borderRadius,
        overflow: "hidden",
        // width: '180px',
        // height: '180px',
        backgroundColor: backgroundColor,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        // p:25,
        ...sx,
      }}
    >
      <Box
        component="img"
        width={sx?.imgWidth}
        src={src}
        alt={alt}
        loading="lazy"
      />
    </Box>
  );
}
