import JoinUs from "components/Organisms/Careers/JoinUs";
import PagesHeader from "components/Molecules/PagesHeader";
import { images } from "assets/AssetHelper";

export default function Careers({
  careerPageData,
  educationDegreesData,
  jobPositionsData,
}) {
  return (
    <>
      <PagesHeader
        img={images?.careersImg}
        title="JOIN US"
        description="We are a team of innovators who value skill, imagination and elegant solutions. Join us now !        "
      />
      <JoinUs
        jobs={careerPageData?.jobs}
        educationDegreesData={educationDegreesData}
        jobPositionsData={jobPositionsData}
      />
    </>
  );
}
