import { Box, Stack } from "@mui/material";
import { images } from "assets/AssetHelper";
import CustomButton from "components/Atoms/Buttons/CustomButton";
import CustomFlexContainer from "components/Atoms/Containers/CustomFlexContainer";
import WrappingContainer from "components/Atoms/Containers/WrappingContainer";
import SectionHeader from "components/Atoms/Text/SectionHeader";
import NewsCardData from "components/Molecules/News/NewsCardData";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export default function NewsTemplate({ newsPageData }) {
  const navigate = useNavigate();
  const [showMoreNews, setShowMoreNews] = useState(7);

  return (
    <>
      <Box
        sx={{
          backgroundImage:
            "linear-gradient(95.99deg, #D5242F -25.84%, #1F366A 4.13%, #151127 121.71%)",
          py: 40,
        }}
      >
        <WrappingContainer>
          <CustomFlexContainer col sx={{ alignItems: "start", gap: 30 }}>
            <SectionHeader>LATEST NEWS</SectionHeader>
            <Stack
              direction="row"
              flexWrap="wrap"
              rowGap="5rem"
              justifyContent="space-between"
              // alignItems="stretch"
            >
              {newsPageData
                ?.map((data, index) => (
                  <Stack
                    rowGap="16px"
                    direction="row"
                    sx={{
                      bgcolor: "white",
                      width: index == 0 ? "100%" : "47%",
                      cursor: "pointer",
                    }}
                    onClick={(_) => navigate(`/news-details/${data?.id}`)}
                  >
                    <Box sx={{ width: { md: "50%", xs: "100%" } }}>
                      <picture>
                        {data?.image?.slice(0, 3)?.map((img) => (
                          <source
                            style={{ width: "100%", height: "100%" }}
                            key={img?.size}
                            media={`(min-width:${img?.size}px)`}
                            srcSet={img?.image_url}
                          />
                        ))}
                        <Box
                          component="img"
                          width={"100%"}
                          height={"100%"}
                          src={
                            data?.image?.filter((img) => img?.size == 1920)[0]
                              ?.image_url
                          }
                          alt=""
                        />
                      </picture>
                    </Box>
                    <Stack
                      sx={{
                        p: index == 0 ? "5rem 2.5rem" : "2rem",
                        width: { md: "50%", xs: "100%" },
                      }}
                    >
                      <NewsCardData data={data} isMain={index == 0} />
                    </Stack>
                  </Stack>
                ))
                .slice(0, showMoreNews)}
            </Stack>
            <Stack width="100%" justifyContent="center" alignItems="center">
              <Stack
                alignItems="center"
                justifyContent="center"
                sx={{
                  display:
                    showMoreNews < newsPageData?.length ? "block" : "none",
                }}
              >
                <CustomButton
                  onClick={(_) => setShowMoreNews(showMoreNews + 2)}
                  wrapperStyle={{ mx: "auto" }}
                >
                  SHOW MORE
                </CustomButton>
              </Stack>
            </Stack>
          </CustomFlexContainer>
        </WrappingContainer>
      </Box>
    </>
  );
}
