import { Box, Stack, Typography } from "@mui/material";
import { images } from "assets/AssetHelper";
import CustomFlexContainer from "components/Atoms/Containers/CustomFlexContainer";
import WrappingContainer from "components/Atoms/Containers/WrappingContainer";
import SectionHeader from "components/Atoms/Text/SectionHeader";
import CustomSlider from "components/Molecules/Slider/CustomSlider";
import React from "react";
import { useNavigate } from "react-router-dom";

export default function SliderSection({
  sliderData,
  title,
  setIsActive,
  isActive,
  sectionBg,
  setIsCurrent,
  isCurrent,
}) {
  const navigate = useNavigate();
  return (
    <>
      <Box
        sx={{
          background: `url(${images.sliderSectionBg})`,
          backgroundPosition: "center",
          backgroundSize: "100% 100%",
          backgroundRepeat: "no-repeat",
          pt: 30,
          pb: { md: 30, xs: "0px" },
          position: "relative",
          mb: { lg: "100px", md: "80px", xs: "70px" },
        }}
      >
        <WrappingContainer
          sx={{
            textAlign: "center",
          }}
        >
          <Stack
            sx={{
              backgroundColor: "white",
              position: "absolute",
              left: "0px",
              right: "0px",
            }}
          />
          <CustomFlexContainer col gap={15} sx={{ alignItems: "start" }}>
            <SectionHeader sx={{ m: 0, mx: "auto" }}>{title}</SectionHeader>
            {/* 100% width must be used whenever slider placed inside a flexbox*/}
            <Stack
              sx={{
                width: "98%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CustomSlider
                slidesToShowXl={3}
                setIsActive={setIsActive}
                length={sliderData?.length - 1}
                className="our-brands"
                infinite={sliderData?.length > 3 ? true : false}
                xsInfinite={sliderData?.length > 1 ? true : false}
                maxWidth="xl"
                containerPadding="0 !important"
                setIsCurrent={setIsCurrent}
                isActive={isActive}
              >
                {sliderData?.map((data, i) => {
                  return (
                    <Box
                      key={i}
                      onClick={(_) => navigate(`/brands/${data?.id}`)}
                      sx={{
                        borderRadius: "20px",
                        boxShadow: "1px 0px 5px 1px #e5e2e940",
                        background:
                          "linear-gradient(209.53deg, #343888 4.22%, rgb(196 153 197) 118.16%)",
                        transition: "0.5s",
                        pb: "8rem",
                        position: "realtive",
                        justifyContent: "center",
                        transform:
                          isCurrent == i
                            ? "scale(0.7)"
                            : isActive == i
                            ? "scale(1)"
                            : "scale(0.7)",
                        mt: { xl: "100px", lg: "80px" },
                        my: "20px",
                        width: "100%",
                        cursor: "pointer",
                      }}
                    >
                      <Stack
                        sx={{
                          py: 25,
                          px: 5,
                          gap: "15px",
                          mt: "10px",
                          position: "relative",
                        }}
                      >
                        <Box sx={{ mt: "10px !important" }}>
                          <Box
                            component={"img"}
                            src={data?.image}
                            alt={data?.name}
                            sx={{
                              position: "absolute",
                              transform: "translate(-50%, -50%)",
                              top: { xs: "-10px" },
                              left: "50%",
                              width: "100%",
                            }}
                          />
                        </Box>
                        <Typography
                          textTransform={"uppercase"}
                          color="secondary.main"
                          fontSize={"38px"}
                          // fontSize={{
                          //   xl:
                          //     isCurrent == i
                          //       ? "30px"
                          //       : isActive == i
                          //       ? "35px"
                          //       : "30px",
                          //   lg:
                          //     isCurrent == i
                          //       ? "20px"
                          //       : isActive == i
                          //       ? "20px"
                          //       : "20px",
                          //   md:
                          //     isCurrent == i
                          //       ? "28px"
                          //       : isActive == i
                          //       ? "32px"
                          //       : "28px",
                          // }}
                          mb="1.5rem"
                          fontWeight="700"
                          mt={"55px"}
                        >
                          {data?.name}
                          {/* Fusteka */}
                        </Typography>
                        <Typography
                          color="white"
                          fontSize={"32px"}
                          // fontSize={{
                          //   lg:
                          //     isActive == i && sliderData?.length > 3
                          //       ? "32px"
                          //       : "30px",
                          //   md: "18px",
                          //   xs: "13px",
                          // }}
                          fontWeight="400"
                          px="6px"
                        >
                          {data?.description}
                        </Typography>
                        {/* <picture> */}
                        {/* {sliderData?.image?.slice(0, 3)?.map((img) => (
                            <source
                              style={{ width: "100%" }}
                              key={img?.size}
                              media={`(min-width:${img?.size}px)`}
                              srcSet={img?.image_url}
                            />
                          ))} */}
                        {/* <Box
                            component="img"
                            sx={{ mt: "10px" }}
                            width={"100%"}
                            src={
                              sliderData?.image
                            }
                            alt=""
                          /> */}
                        {/* </picture> */}
                      </Stack>
                    </Box>
                  );
                })}
              </CustomSlider>
            </Stack>
          </CustomFlexContainer>
        </WrappingContainer>
      </Box>
    </>
  );
}
