import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Container } from "@mui/material";
import { NextArrow, PrevArrow } from "./SliderArrows";

export default function CustomSlider({
  children,
  setIsActive,
  className,
  slidesToShowXl,
  maxWidth,
  infinite = true,
  length,
  setActiveSlides,
  containerPadding,
  fade,
  galleryId,
  xsInfinite,
  rows,
  slidesToShowMd = 1,
  setIsCurrent,
  isActive
}) {
  const settings = {
    infinite: infinite,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    fade: fade ? fade : false,
    rows: rows || 1,
    slidesPerRow: 1,
    pauseOnFocus: false,
    // pauseOnHover: false,
    // autoplay: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    cssEase: "ease-in-out",
    beforeChange(current, next) {
        if (current == length) {
          setIsActive(() => 0);
          setIsCurrent(() => 0);
        } else {
          setIsCurrent(() => current + 1);
          setIsActive(() => current + 1);        
        }
     
    },
    afterChange(current) {
      setTimeout(() => {
        if (current == length) {
          setIsActive(() => 0);
        } else {
          setIsActive(() => current + 1);
        }
      }, 300);
    },
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: slidesToShowXl,
          slidesToScroll: 1,
          infinite: false,
          initialSlide: 0,
        }
      },
      {
        breakpoint: 1020,
        settings: {
          slidesToShow: slidesToShowMd,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
          infinite: xsInfinite,
          rows: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          fade: true,
          infinite: xsInfinite,
          rows: 1,
        },
      },
    ],
  };
  return (
    <>
      <Container
        maxWidth={maxWidth}
        sx={{ margin: 0, padding: containerPadding }}
      >
        <Slider
          {...settings}
          className={className}
          initialSlide={galleryId || 0}
        >
          {children}
        </Slider>
      </Container>
    </>
  );
}
