import Types from "./Types";

export const AboutDataSuccess = (payload) => ({
  type: Types.ABOUT_PAGE_DATA_SUCCESS,
  payload,
});

export const AboutDataLoading = (payload) => ({
  type: Types.ABOUT_PAGE_DATA_LOADING,
  payload,
});
