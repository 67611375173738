import {
  Box,
  Grid,
  Stack,
  Typography,
  Card,
  CardContent,
  CardMedia,
} from "@mui/material";
import WrappingContainer from "components/Atoms/Containers/WrappingContainer";
import SectionHeader from "components/Atoms/Text/SectionHeader";
import CustomFlexContainer from "components/Atoms/Containers/CustomFlexContainer";
import CustomButton from "components/Atoms/Buttons/CustomButton";
import { useNavigate } from "react-router-dom";
import NewsCardData from "components/Molecules/News/NewsCardData";
import Motion from "components/Atoms/SharedComponent/Motion";
import {
  fadeInLeft,
  fadeInRight,
} from "components/Atoms/SharedComponent/aniamtionDirection";
export default function LatestNews({ newsData }) {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        backgroundImage:
          "linear-gradient(95.99deg, #D5242F -25.84%, #1F366A 4.13%, #151127 121.71%)",
        py: 40,
      }}
    >
      <WrappingContainer>
        <CustomFlexContainer col sx={{ alignItems: "start", gap: 30 }}>
          <SectionHeader>LATEST NEWS</SectionHeader>
          <Stack
            sx={{
              width: "100%",
            }}
            flexWrap="wrap"
            direction="row"
            justifyContent="space-between"
          >
            <Motion
              {...fadeInLeft({
                width: { lg: "45%", md: "48%", sm: "50%", xs: "47%" },
                duration: 1.5,
              })}
              width="48%"
            >
              <Stack width={"100%"} bgcolor="white" borderRadius={"4px"}>
                <Box
                  component={"img"}
                  src={newsData?.[0]?.image[0]?.image_url}
                  width="100%"
                  borderRadius={"4px 4px 0 0"}
                />
                <Box sx={{ padding: 10, direction: "rtl" }}>
                  <NewsCardData data={newsData?.[0]} isActive={true} />
                </Box>
              </Stack>
            </Motion>
            <Motion
              {...fadeInRight({
                width: { lg: "45%", md: "48%", sm: "50%", xs: "47%" },
                duration: 1.5,
              })}
              width="48%"
            >
              <Stack width={"100%"}>
                {newsData?.map(
                  (data, i) =>
                    i > 0 && (
                      <Stack direction={"row"} mb="25px">
                        <Box
                          component={"img"}
                          src={data?.image[0]?.image_url}
                          borderRadius={"4px 0px 0 4px"}
                          width={"33%"}
                        />
                        <Box
                          sx={{
                            padding: 10,
                            direction: "rtl",
                            bgcolor: "white",
                          }}
                          borderRadius={"0 4px 4px 0"}
                        >
                          <NewsCardData data={data} />
                        </Box>
                      </Stack>
                    )
                )}
                <Box
                  sx={{
                    mt: "30px",
                    display: "inline",
                    mx: { sm: 0, xs: "auto" },
                  }}
                >
                  <CustomButton onClick={(_) => navigate("/latest-news")}>
                    SHOW MORE
                  </CustomButton>
                </Box>
              </Stack>
            </Motion>
          </Stack>
        </CustomFlexContainer>
      </WrappingContainer>
    </Box>
  );
}

// {
//   /* {newsData?.map((data, i) => (
//               <Card
//                 sx={{
//                   maxWidth: { md: "48%" },
//                   height: { xs: i === 0 ? "100%" : "37%" },
//                   display: "flex",
//                   flexDirection: {
//                     xs: i === 0 ? "column" : "row",
//                   },
//                   cursor: "pointer",
//                   overflow: "hidden",
//                 }}
//                 key={data.id}
//                 onClick={(_) => navigate(`news-details/${data?.id}`)}
//               >
//                 <CardMedia
//                   sx={{
//                     height: {
//                       md: i === 0 ? "100%" : "100%",
//                       xs: i === 0 ? 400 : 300,
//                     },
//                     width: {
//                       sm: i === 0 ? "100%" : "50%",
//                       xs: i === 0 ? "100%" : "40%",
//                     },
//                   }}
//                   image={data?.image[0]?.image_url}
//                 />
//                 <CardContent
//                   sx={{
//                     width: {
//                       xs: i === 0 ? "95%" : "80%",
//                     },
//                     display: "flex",
//                     flexDirection: "column",
//                     // gap: 10,
//                   }}
//                   mb="30px"
//                 >
//                   <NewsCardData data={data} />
//                 </CardContent>
//               </Card>
//             ))}
//             <Box
//               sx={{ mt: "30px", display: "inline", mx: { sm: 0, xs: "auto" } }}
//             >
//               <CustomButton onClick={(_) => navigate("/latest-news")}>
//                 SHOW MORE
//               </CustomButton>
//             </Box>
// }
