import { Typography, Box, AccordionDetails } from "@mui/material";
import UploadResume from "./UploadResume";

export default function OpenVacancy({ vacancy }) {
  const { jobDescription, responsibilities, requirements } = vacancy;
  return (
    <AccordionDetails
      sx={{ display: "flex", flexDirection: "column", gap: { md: 15, xs:3 } }}
    >
      <Box>
        <Typography fontSize={{ md: "18px", xs: '16px' }} sx={{ fontFamily: "Poppins ", fontWeight: 300 }}>
          {jobDescription}
        </Typography>
      </Box>
      <Box>
        {responsibilities && (
          <>
            <Typography fontSize={{ md: "18px", xs: '14px' }}>Responsibilities</Typography>
            <Typography
              sx={{ px: 0, py: { md: 5 }, fontSize: { md: "14px", xs: '12px' } }}
              dangerouslySetInnerHTML={{ __html: responsibilities }}
            />
          </>
        )}
        {requirements && (
          <>
            <Typography fontSize={{ md: "18px", xs: '14px' }}>Requirements</Typography>
            <Typography
              sx={{ px: 0, py: 5, fontSize: { md: "14px", xs: '12px' } }}
              dangerouslySetInnerHTML={{ __html: requirements }}
            />
          </>
        )}
      </Box>
      <UploadResume title={vacancy?.title} id={vacancy?.id} />
    </AccordionDetails>
  );
}
