import { useState } from "react";
import { Typography, Box, Stack } from "@mui/material";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import WrappingContainer from "components/Atoms/Containers/WrappingContainer";
import CustomFlexContainer from "components/Atoms/Containers/CustomFlexContainer";
import CustomSlider from "components/Molecules/Slider/CustomSlider";
import { useThemePalette } from "common/hooks/theme_palette";

export default function Values({ valuesData }) {
  //! keeping track of the next centered item to apply styles

  const [isActive, setIsActive] = useState(1);
  const { lightGray } = useThemePalette();
  const [isCurrent, setIsCurrent] = useState();

  return (
    <WrappingContainer
      sx={{
        backgroundColor: "rgba(0, 0, 0, 0.22)",
        textAlign: "center",
        py: 20,
      }}
    >
      <CustomFlexContainer col gap={15}>
        <Typography
          variant="h3"
          color="secondary"
          fontWeight="600"
          letterSpacing="6px"
          fontSize={{ xl: "60px", md: "40px", sm: "25px", xs: "19px" }}
        >
          OUR VALUES
        </Typography>
        <Box sx={{ width: { lg: "95%", md: "90%", xs: "90%" } }}>
          <CustomSlider
            slidesToShowXl={3}
            setIsActive={setIsActive}
            length={valuesData?.length - 1}
            className="our-story"
            infinite={valuesData?.length > 3 ? true : false}
            xsInfinite={valuesData?.length > 1 ? true : false}
            maxWidth="xl"
            containerPadding="0 !important"
            setIsCurrent={setIsCurrent}
          >
            {valuesData?.map((value, i) => (
              <>
                <Card
                  sx={{
                    borderRadius: "8px",
                    overflow: "hidden",
                    transform:
                      isCurrent == i
                        ? "scale(0.65) "
                        : isActive == i
                        ? "scale(1)"
                        : "scale(0.65)",
                    transition: "0.5s",
                    backgroundColor: "rgba(0, 0, 0, 0.22)",
                    color: "white",
                    minHeight: 500,
                    my: 15,
                  }}
                  key={value.id}
                >
                  <Stack
                    sx={{ display: "flex", flexDirection: "column", gap: 10 }}
                    alignItems="stretch"
                  >
                    <CardMedia
                      component="img"
                      height="250"
                      image={value?.image[0]?.image_url}
                      alt={value.alt}
                    />
                    <Stack
                      sx={{
                        px: "12px",
                        pb: "65px",
                      }}
                    >
                      <Typography
                        gutterBottom
                        variant="h5"
                        fontWeight="700"
                        letterSpacing="4px"
                        fontSize={"44px"}
                        py="10px"
                      >
                        {value?.title}
                      </Typography>
                      <Typography
                        variant="body2"
                        fontSize={"35px"}
                        letterSpacing="1px"
                        fontWeight="500"
                        px="16px"
                        color={lightGray}
                        minHeight={"300px"}
                      >
                        {value?.description}
                      </Typography>
                    </Stack>
                  </Stack>
                </Card>
              </>
            ))}
          </CustomSlider>
        </Box>
      </CustomFlexContainer>
    </WrappingContainer>
  );
}
