import {
  CustomCard,
  CardContent,
  CardMedia,
} from "components/Molecules/About/customCard";
import { Typography } from "@mui/material";
import misson from "../../../assets/images/about/mission.png";

export default function Mission({ missionData, targetElementRef,imgTargerElementRef }) {
  return (
    <CustomCard>
      {/* content */}
      <CardContent targetElementRef={targetElementRef}>
        <Typography
          variant="h4"
          fontSize={{ md: "50px", sm: "30px", xs: "15px" }}
          mb={10}
          fontWeight="600"
          color="#EDB41D"
          letterSpacing="4px"
          textTransform={"uppercase"}
          textAlign={{ md: "start", xs: "center" }}
          mt="50px"
        >
          OUR MISSION
        </Typography>
        <Typography
          variant="body1"
          fontSize={{ lg: "27px", xs: "15px" }}
          fontWeight="600"
          color="white"
          lineHeight={{ md: "30px", lg: "42px", xl: "42px" }}
          textAlign={{ md: "start", xs: "center" }}
          width={{ xl: "100%", lg: "82%", md: "75%" }}
        >
          {missionData?.mission_description}
        </Typography>
      </CardContent>
      {/* image */}
      <CardMedia
        src={missionData?.mission_image[0]?.image_url}
        alt="mission"
        targetElementRef={imgTargerElementRef}
      />
    </CustomCard>
  );
}
