import { Box, Button } from "@mui/material";



export default function CustomButton({ children, onClick, sx, wrapperStyle }) {

  const commonStyle = {
    background:
      "linear-gradient(to right, #FFC728 15%,transparent 15%, transparent 50%, transparent 50%, transparent 85%, #FFC728 85%)",
    content: '""',
    height: "1px",
    right: "0",
    left: "0",
    bottom: "0",
    position: "absolute",
  };
  const wrapperSx = {
    cursor: "pointer",
    textAlign: "center",
    position: "relative",
    width: "fit-content",
    py: 4,
    px: { md: 20, sm: 15, xs: 7 },
    top: { lg: 0, md: 15, sm: '21px', xs: '22px' },
    borderLeft: "1px solid ",
    borderRight: "1px solid ",
    borderColor: "secondary.main",
    display: 'flex',
    ...wrapperStyle,
    "&:after": {
      ...commonStyle,
      top: "0",
    },
    "&:before": {
      ...commonStyle,
      bottom: "0",
    },
  };
  const buttonSx = {
    letterSpacing: "0.2px",
    fontFamily: "Inter",
    fontSize: { sm: "20px", xs: '13px' },
    fontWeight: "400px",
    "&:hover": {
      backgroundColor: "transparent !important",
    },
  };

  const handleClick = () => {
    onClick && onClick();
  };
  return (
    <Box onClick={handleClick} sx={wrapperSx}>
      <Button sx={{ ...buttonSx, ...sx }} color="secondary">
        {children}
      </Button>
    </Box>
  );
}
