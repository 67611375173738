import { reduxRequest } from "../utils/reduxRequest";
import { NewsDataLoading, NewsDataSuccess, NewsDetailsDataSuccess } from "./Actions";

export const newsPageDataRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: NewsDataLoading,
    successFunction: NewsDataSuccess,
    url: "/group/news",
    method: "post",
    ...variables,
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};
export const newsDetailsDataRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: NewsDataLoading,
    successFunction: NewsDetailsDataSuccess,
    url: `/group/news/${variables?.id}`,
    method: "post",
    ...variables,
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};
