import { useEffect, useState } from "react";
import { AppBar, IconButton, Box, Toolbar, Stack } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";

import WrappingContainer from "../../Atoms/Containers/WrappingContainer";
import NavMenu from "../../Molecules/Navbar/NavMenu";
import NavDrawer from "../../Molecules/Navbar/NavDrawer";
import logo from "../../../assets/images/logo.svg";
import FustukaGroupLogo from "../../../assets/images/FustukaGroupLogo.svg";
import { brands } from "data";
import { useDispatch, useSelector } from "react-redux";
import { brandsPageDataRequest } from "services/modules/brands";

const drawerWidth = 240;

export default function Navbar({ brandsPageData, navbarRef }) {
  const navItems = [
    {
      name: "Home",
      path: "/",
      type: "text",
    },
    {
      name: "About us",
      path: "/about",
      type: "text",
    },
    {
      name: "brands",
      path: "/brands",
      type: "drop-down",
      options: brandsPageData,
    },
    {
      name: "Careers",
      path: "/careers",
      type: "text",
    },
    {
      name: "Contact Us",
      path: "/contact",
      type: "text",
    },
  ];
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
    console.log("clicked");
  };

  const appBarStyles = {
    backgroundColor: "white",
    py: 5,
    boxShadow: "none",
    position: "relative",
    textTransform: "uppercase",
    display: "flex",
    alignItems: "end !important",
  };
  return (
    <>
      <Box
        sx={{
          display: "flex",
          position: "fixed",
          right: 0,
          left: 0,
          top: 0,
          zIndex: 9999,
        }}
      >
        <AppBar position="fixed" component="nav" sx={appBarStyles} ref={navbarRef}>
          <WrappingContainer sx={{ m: "0" }}>
            <Toolbar
              sx={{
                display: "flex",
                p: "0 !important",
              }}
            >
              <Stack
                direction="row"
                sx={{ display: { md: "none" }, width: "100%" }}
              >
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  width={"50%"}
                >
                  <IconButton
                    aria-label="open drawer"
                    edge="start"
                    onClick={handleDrawerToggle}
                    sx={{ mr: 2, color: "#042353" }}
                  >
                    <MenuIcon />
                  </IconButton>
                  <Box>
                    <img
                      src={FustukaGroupLogo}
                      alt="Fusteka Group"
                      width="100%"
                      loading="lazy"
                    />
                  </Box>
                </Stack>
              </Stack>

              <Box
                component="div"
                sx={{ flexGrow: 1, display: { xs: "none", md: "block" } }}
              >
                <img
                  src={logo}
                  width="33%"
                  alt="Fusteka Group"
                  loading="lazy"
                />
              </Box>

              <NavMenu brandsPageData={brandsPageData} navItems={navItems} />
            </Toolbar>
          </WrappingContainer>
        </AppBar>

        <NavDrawer
          mobileOpen={mobileOpen}
          navItems={navItems}
          handleDrawerToggle={handleDrawerToggle}
          drawerWidth={drawerWidth}
          logo={logo}
          brandsPageData={brandsPageData}
        />
      </Box>
    </>
  );
}
