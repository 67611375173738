import { Box, Stack, Typography } from "@mui/material";
import SectionHeader from "components/Atoms/Text/SectionHeader";
import React from "react";

export default function PagesHeader({ title, img, description }) {
  return (
    <>
      <Stack
        position="relative"
        sx={{ mb: { lg: "100px", md: "80px", xs: "70px" } }}
      >
        <Box component="img" src={img} alt="head img" />
        <Box
          sx={{
            background:
              "linear-gradient(180deg, #031125 8.05%, rgba(3, 17, 37, 0.00) 166.44%)",
            position: "absolute",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            width: "100%",
            height: "100%",
          }}
        >
          <Stack
            height="100%"
            rowGap={"1rem"}
            justifyContent="center"
            alignItems="center"
          >
            <SectionHeader sx={{ fontSize: "80px" }}>{title}</SectionHeader>
            <Typography
              variant="body1"
              color="white"
              sx={{
                fontFamily: "Poppins",
                fontSize: { xl: "25px", md: "20px", sm: "18px", xs: "14px" },
                fontWeight: "400",
                width: { md: "60%", sm: "80%", xs: "100%" },
                textAlign: "center",
                lineHeight: "40px",
              }}
            >
              {description}
            </Typography>
          </Stack>
        </Box>
      </Stack>
    </>
  );
}
