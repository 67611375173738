// import Logo from "./images/logo.png"
import Usersetting from "./icons/usersetting.svg"
import Home from "./icons/home.svg"
import User from "./icons/user.svg"
import Assistant from "./icons/assistant.svg"
import Courses from "./icons/courses.svg"
import Setting from "./icons/setting.svg"
import imageIcon from './icons/image.svg';
import deleteIcon from './icons/delete.svg';
import editIcon from './icons/edit.svg';
import videoIcon from './icons/video.svg';
import exportIcon from './icons/export.svg';
import plusCircleIcon from './icons/plus-circle.svg';
import searchIcon from './icons/search.svg';
import filterIcon from './icons/filter.svg';
import AddPersonIcon from './icons/add-person.svg'
import SourceIcon from './icons/source.svg'
import AttachedIcon from './icons/attached.svg'
import EmptyQuestionsIcons from './icons/empty-questions.svg'
import QuestionBankIcon from './icons/question-bank.svg'
import Delete from './icons/Delete-icon.svg'
import Download from './icons/Download.svg'
import Mask from './dummy/Mask.png'
import silverArrowPrev from "./icons/silver-arrow-prev.svg"
import silverArrowNext from "./icons/silver-arrow-next.svg"
import yellowArrowPrev from "./icons/yellow-arrow-prev.svg"
import yellowArrowNext from "./icons/yellow-arrow-next.svg"
import metallicBar from "./images/home/about/metallic_bar.png"
import latestNewsImage from "./images/home/latest news/latest_news.png"
import gradientBackground from "./images/backgrounds/gradient-background.png"
import aboutBrands1 from "./images/brands/aboutBrands1.png"
import aboutBrands2 from "./images/brands/aboutBrands2.png"
import Years from "./images/brands/Years.png"
import Slogan from "./images/brands/Slogan.png"
import yearsMobile from "./images/brands/yearsMobile.png"
import yearsWebImg from "./images/brands/yearsWebImg.png"
import yearsMedium from "./images/brands/yearsMedium.png"
import homeVideo from "./images/homeVideo.png"
import latestNews from "./images/latest_news.png"
import contactHeadImg from "./images/contactPage.png"
import careersImg from "./images/careersImg.png"
import homeVideoImg from "./images/homeVideoImg.png"
import brandsImg from "./images/brandsImg.png"
import playIcon from "./icons/playIcon.svg"
import sliderSectionBg from "./images/sliderSectionBg.png"
import brandsBg from "./images/brandsBg.png"

export const videos = {
}

export const images = {
    brandsBg,
    homeVideoImg,
    brandsImg,
    careersImg,
    contactHeadImg,
    latestNews,
    yearsMedium,
    yearsWebImg,
    yearsMobile,
    metallicBar,
    latestNewsImage,
    gradientBackground,
    aboutBrands1,
    aboutBrands2,
    Years,
    Slogan,
    homeVideo,
    sliderSectionBg
}

export const icons = {
    playIcon,
    Usersetting,
    Home,
    User,
    Assistant,
    Courses,
    Setting,
    imageIcon,
    deleteIcon,
    editIcon,
    videoIcon,
    exportIcon,
    plusCircleIcon,
    searchIcon,
    filterIcon,
    AddPersonIcon,
    SourceIcon,
    AttachedIcon,
    EmptyQuestionsIcons,
    QuestionBankIcon,
    Delete,
    Download,
    yellowArrowPrev,
    yellowArrowNext,
    silverArrowPrev,
    silverArrowNext
}