import {
  Box,
  Drawer,
  Typography,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  MenuList,
  MenuItem,
  Link as MUILink,
} from "@mui/material";

import { NavLink } from "react-router-dom";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import KeyboardArrowUpOutlinedIcon from "@mui/icons-material/KeyboardArrowUpOutlined";
import CustomFlexContainer from "components/Atoms/Containers/CustomFlexContainer";
import { useState } from "react";

export default function NavDrawer({
  mobileOpen,
  navItems,
  handleDrawerToggle,
  drawerWidth,
  logo,
}) {
  const [isExpanded, setIsExpanded] = useState(false);
  const handleClick = (e) => {
    e.stopPropagation();
    setIsExpanded(!isExpanded);
  };
  const drawer = (
    <Box
      onClick={handleDrawerToggle}
      sx={{
        fontSize: "18px",
        fontFamily: "Poppins",
        color: "#042353",
        //when using NavLink element from react-router-dom
        // .active class will be added to the active link
        "& .active": {
          color: "#FFC757",
          height: "100%",
          width: "100%",
        },
      }}
    >
      <CustomFlexContainer variant="h6" sx={{ my: 5 }}>
        <img src={logo} alt="Fusteka Group" />
      </CustomFlexContainer>
      <Divider />
      <List>
        {navItems?.map((item) => (
          <ListItem key={item.name} disablePadding>
            <ListItemButton >
              {item.type === "drop-down" ? (
                <ListItemText
                  primary={
                    <Box
                      sx={{
                        display: "flex",
                        // justifyContent: "center",
                        // alignItems: "center",
                        flexDirection: isExpanded && "column",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          // justifyContent: "center",
                          // alignItems: "center",
                          gap: 5,
                        }}
                      >
                        <NavLink to={item.path}>{item.name}</NavLink>
                        <Box onClick={handleClick} sx={{ display: "flex" }}>
                          {isExpanded ? (
                            <KeyboardArrowUpOutlinedIcon />
                          ) : (
                            <KeyboardArrowDownOutlinedIcon />
                          )}
                        </Box>
                      </Box>
                      {isExpanded && (
                        <MenuList id="split-button-menu" autoFocusItem>
                          {item.options.map((option, index) => (
                            <MenuItem key={index}>
                              <MUILink underline="none">
                                <NavLink  to={`brands/${option?.id}`}>
                                  {option.name}
                                </NavLink>
                              </MUILink>
                            </MenuItem>
                          ))}
                        </MenuList>
                      )}
                    </Box>
                  }
                />
              ) : (
                <ListItemText
                  primary={<NavLink to={item.path}>{item.name}</NavLink>}
                />
              )}
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );
  return (
    <Box component="nav">
      <Drawer
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: "block" },
          "& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth },
        }}
      >
        {drawer}
      </Drawer>
    </Box>
  );
}
