import { Box, Typography } from '@mui/material';

import CompanyInfoGridItem from './CompanyInfoGridItem';
import WrappingContainer from 'components/Atoms/Containers/WrappingContainer';
import CustomFlexContainer from 'components/Atoms/Containers/CustomFlexContainer';

export default function CompanyInfo({ info }) {

   const gridContainerStyle = {
      display: 'grid',
      gridTemplateColumns: { xs: 'repeat(2, 1fr)', md: 'repeat(4, 1fr)' },
      gridTemplateRows: { xs: 'repeat(12, 1fr)', md: 'repeat(6, 1fr)' },
      rowGap: 100,
      columnGap: 20,
      minHeight: '70vh',
      width: '100%',
   };

   return (
      <Box>
         <WrappingContainer sx={{ py: 60 }}>
            <CustomFlexContainer col gap={25}>
               <Typography
                  sx={{ fontWeight: 900, color: '#202249', textAlign: 'center' }}
                  variant="h2"
               >
                  COMPANY INFORMATION
               </Typography>
               <div style={gridContainerStyle}>
                  {info.map((data, i) => (
                     <CompanyInfoGridItem key={i} data={data} />
                  ))}
               </div>
            </CustomFlexContainer>
         </WrappingContainer>
      </Box>
   );
}
