/* eslint-disable array-callback-return */
import Types from "./Types";

const INIT_STATE = {
  careerPageData: [],
  jobPositionsData:[],
  educationDegreesData:[],
  isLoading: {
    type: "",
    loading: false,
  },
};

export default function careerPageReducer(state = INIT_STATE, action) {
  const { type, payload } = action;
  switch (type) {
    case Types.CAREER_PAGE_DATA_LOADING: {
      return {
        ...state,
        isLoading: payload,
      };
    }
    case Types.CAREER_PAGE_DATA_SUCCESS: {
      return {
        ...state,
        careerPageData: payload?.result?.data,
      };
    }
    case Types.CAREERS_DROP_DOWN_DATA_SUCCESS: {
      return {
        ...state,
        jobPositionsData: payload?.result?.data?.job_positions,
        educationDegreesData: payload?.result?.data?.education_degrees,
      };
    }
    default: {
      return state;
    }
  }
}
