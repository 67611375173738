
// brands
import jekor from "./assets/images/brands/jekor/logo.svg";
import virtualWorldGroup from "./assets/images/brands/virtual-world/logo.svg";
import khayratAlKhaseb from "./assets/images/brands/Khayrat/logo.svg";
import alAmeri from "./assets/images/brands/alameri/logo.svg";
import fusteka from "./assets/images/brands/fusteka/logo.svg";
import alErwa from "./assets/images/brands/alerwa/logo.svg";
import basrahCenter from "./assets/images/brands/basra/logo.svg";
// certificatioons
import certificate1 from "./assets/images/home/certificates/certificate1.png";
import certificate2 from "./assets/images/home/certificates/certificate2.png";
import certificate3 from "./assets/images/home/certificates/certificate3.png";
// contact info
import phone from "./assets/icons/phone.svg";
import location from "./assets/icons/location.svg";
import email from "./assets/icons/email.svg";
//social media icons
import facebook from "./assets/icons/facebook.svg";
import linkedIn from "./assets/icons/linkedIn.svg";
import twitter from "./assets/icons/twitter.svg";
import instagram from "./assets/icons/instagram.svg";

//members personal photos
import member1 from "./assets/images/about/members/member1.png";
import member2 from "./assets/images/about/members/member2.png";
import member3 from "./assets/images/about/members/member3.png";
import member4 from "./assets/images/about/members/member4.png";
// members social media
import memberTwitter from "./assets/icons/memberTwitter.svg";
import memberInstagram from "./assets/icons/memberInstagram.svg";
import memberLinkedIn from "./assets/icons/memberLinkedIn.svg";
//values
import value1 from "./assets/images/about/our values/two-business-men-celebrating-deal.png";
import value2 from "./assets/images/about/our values/serious-boss-with-workers-background.png";
import value3 from "./assets/images/about/our values/front-view-businessman-with-colorful-cones-representing-growth 1.png";
//milestones
import yearbackground1 from "./assets/images/about/milestones/yearbackground1.png";
import yearbackground2 from "./assets/images/about/milestones/yearbackground2.png";
import yearbackground3 from "./assets/images/about/milestones/yearbackground3.png";
import yearbackground4 from "./assets/images/about/milestones/yearbackground4.png";
import yearbackground5 from "./assets/images/about/milestones/yearbackground5.png";
import yearbackground6 from "./assets/images/about/milestones/yearbackground6.png";
//Jekor info
import JekorInfo1 from "./assets/images/brands/jekor/agricultural-silos-building-exterior.png";
import JekorInfo2 from "./assets/images/brands/jekor/business-people-with-arms-crossed-one-with-hands-pockets.png";
import JekorInfo3 from "./assets/images/brands/jekor/view-full-warehouse-with-forklift.jpg";
import JekorInfo4 from "./assets/images/brands/jekor/young-man-working-warehouse-with-boxes.png";
import JekorInfo5 from "./assets/images/brands/jekor/interior-view-steel-factory.png";
import JekorInfo6 from "./assets/images/brands/jekor/factory-workshop-interior-machines-glass-production-background.png";
import JekorInfo7 from "./assets/images/brands/jekor/workman-store-house-orange-helmet.png";
//jekor brandHeader
import jekorBrandHeader from "./assets/images/brands/jekor/header.png";
//jekor products
import meatItemsType from "./assets/images/brands/jekor/products/side-view-chef-makes-raw-marbled-beef-steaks-stand.png";
import chickenItemsType from "./assets/images/brands/jekor/products/side-view-chef-with-apron-gloves-cutting-chicken.png";
import vegItemsType from "./assets/images/brands/jekor/products/assortment-frozen-food-table.png";
// import cheeseItemsType from "./assets/images/brands/jekor/products/photo-cheese-factory-switzerland-typical-italian-cheese-parmigiano-reggiano-thousands-forms-parmigiano-reggiano-largest-parmesan-cheese-storage-forms-cheese-ripening.png";
//fusteka brandHeader
import fustekaBrandHeader from "./assets/images/brands/fusteka/header.png";
//fusteka products
import fromStickToCups from "./assets/images/brands/fusteka/products/from-stick-to-cups.png";
import fromConesToSandwiches from "./assets/images/brands/fusteka/products/from_cones_to_sandwiches.png";
import toyIcecream from "./assets/images/brands/fusteka/products/toy_icecream.png";
import noveltiesInGlass from "./assets/images/brands/fusteka/products/novelties_in_glass.png";
import withFruits from "./assets/images/brands/fusteka/products/with_fruits.png";
//virtual world brandHeader
import virtualWolrdBrandHeader from "./assets/images/brands/virtual-world/header.png";
//virtual world info
import shopini from "./assets/images/brands/virtual-world/info/online-purchasing-payment-e-commerce-banking.png";
import shopiniExpress from "./assets/images/brands/virtual-world/info/content-client-receiving-male.png";
// import shopiniWorld from "./assets/images/brands/virtual-world/info/logistics-transportation-container-cargo-ship-cargo-plane-with-working-crane-bridge-shipyard-sunrise-logistic-import-export-transport-industry-background-ai-generative.png";
import headQuarters from "./assets/images/brands/virtual-world/info/glass-designed-building-view.png";
import afiet from "./assets/images/brands/virtual-world/info/close-up-delivery-guy-holding-pizza-boxes.png";
//Alameri
import alameriHeaderImage from "./assets/images/brands/alameri/header.png";
//info
import pipeline from "./assets/images/brands/alameri/info/person-working-building-construction.png";
import transportation from "./assets/images/brands/alameri/info/cranes-seaport.png";
import steelStructure from "./assets/images/brands/alameri/info/cheese-industry-interior-production-hard-cheese-background.png";
import trucks from "./assets/images/brands/alameri/info/construction-heavy-equipment-site-sunny-da 1.png";
import civilConstruction from "./assets/images/brands/alameri/info/building-new-concrete-houses 1.png";
import waterSystem from "./assets/images/brands/alameri/info/bridge-sea-sunlight 1.png";
import brands1 from "./assets/images/brands/brands1.png";

const brands = {
  jekor: {
    id: 1,
    name: "JEKOR FROZEN FOOD",
    description:
      "Lorem ipsum dolor sit amet consectetur. Euismod eu morbi at duis. Congue amet",
    logo: {
      src: jekor,
      alt: "JEKOR FROZEN FOOD",
    },
    about:
      "Welcome to Fusteka Ice Cream! We are proud to have been serving our delicious ice cream since 2001. Our passion for creating the highest quality ice cream using only the freshest ingredients has been guiding principle from the beginning Our passion for creating the highest quality ice cream using only the freshest ingredients has been our.",
    brandHeaderImage: {
      src: brands1,
      alt: "jekor header image",
    },
    url: "",
    path: "/brands/1",
    info: [
      {
        comment: "3 Factories",
        image: JekorInfo1,
      },
      {
        comment: "985 Employee",
        image: JekorInfo2,
      },
      {
        comment: "70 Items",
        image: JekorInfo3,
      },
      {
        comment: "39 Distributor in Iraq",
        image: JekorInfo4,
      },
      {
        comment: "21 Production line",
        image: JekorInfo5,
      },
      {
        comment: "100 million L / year",
        image: JekorInfo6,
      },
      {
        comment: "+10 New references/year",
        image: JekorInfo7,
      },
    ],
    products: [
      {
        id: 1,
        img: {
          src: meatItemsType,
          alt: "Meat Items Type",
        },
        name: "Meat Items Type",
      },
      {
        id: 2,
        img: {
          src: chickenItemsType,
          alt: "Chicken Items Type",
        },
        name: "Chicken Items Type",
      },
      {
        id: 3,
        img: {
          src: vegItemsType,
          alt: "Vegetables Items Type",
        },
        name: "Vegetables Items Type",
      },
      {
        id: 4,
        img: {
          src: vegItemsType,
          alt: "Vegetables Items Type",
        },
        name: "Vegetables Items Type",
      },
      {
        id: 5,
        img: {
          src: vegItemsType,
          alt: "Vegetables Items Type",
        },
        name: "Vegetables Items Type",
      },
      {
        id: 6,
        img: {
          src: vegItemsType,
          alt: "Vegetables Items Type",
        },
        name: "Vegetables Items Type",
      },
      {
        id: 7,
        img: {
          src: vegItemsType,
          alt: "Vegetables Items Type",
        },
        name: "Vegetables Items Type",
      },
      {
        id: 8,
        img: {
          src: vegItemsType,
          alt: "Vegetables Items Type",
        },
        name: "Vegetables Items Type",
      },
      // {
      //   id: 4,
      //   img: {
      //     src: cheeseItemsType,
      //     alt: "Cheese Items Type",
      //   },
      //   name: "Cheese Items Type",
      // },
    ],
  },
  alerwa: {
    id: 2,
    name: "AL ERWA",
    description:
      "Lorem ipsum dolor sit amet consectetur. Euismod eu morbi at duis. Congue amet",
    logo: {
      src: alErwa,
      alt: "AL ERWA",
    },
    url: "",
    path: "/brands/2",
    info: [],
    producst: [],
  },
  virtualWorld: {
    id: 3,
    name: "VIRTUAL WORLD GROUP",
    description:
      "Lorem ipsum dolor sit amet consectetur. Euismod eu morbi at duis. Congue amet",
    logo: {
      src: virtualWorldGroup,
      alt: "VIRTUAL WORLD GROUP",
    },
    url: "",
    about:
      "Welcome to Fusteka Ice Cream! We are proud to have been serving our delicious ice cream since 2001. Our passion for creating the highest quality ice cream using only the freshest ingredients has been guiding principle from the beginning Our passion for creating the highest quality ice cream using only the freshest ingredients has been our.",

    path: "/brands/3",
    brandHeaderImage: {
      src: virtualWolrdBrandHeader,
      alt: "virtual world header",
    },
    info: [
      {
        image: { src: shopini, alt: "Shopini" },
        comment: " is an E commerce marketplace operating in Basra, Iraq.",
        name: "Shopini",
      },
      {
        image: { src: shopiniExpress, alt: "Shopini Express" },
        comment: " is a courier service operating in Iraq.",
        name: "Shopini Express",
      },
      {
        // image: { src: shopiniWorld, alt: "Shopini World" },
        comment: " is an international shipping website & Logistics company.",
        name: "Shopini World",
      },
      {
        image: { src: headQuarters, alt: "Head Quarters" },
        comment: "Headquarters are based in Dubai, UAE",
        name: " ",
      },
      {
        image: { src: afiet, alt: "afiet" },
        comment:
          " is an online food delivery service operating in Basra, Iraq.",
        name: "Afiet",
      },
    ],
    products: [],
  },
  basra: {
    id: 4,
    name: "BASRAH CENTER",
    description:
      "Lorem ipsum dolor sit amet consectetur. Euismod eu morbi at duis. Congue amet",
    logo: {
      src: basrahCenter,
      alt: "BASRAH CENTER",
    },
    url: "",
    path: "/brands/4",

    info: [],
    products: [],
  },
  khayratAlKhaseb: {
    id: 5,
    name: "KHAYRAT EL KHASIB",
    description:
      "Lorem ipsum dolor sit amet consectetur. Euismod eu morbi at duis. Congue amet",
    logo: {
      src: khayratAlKhaseb,
      alt: "KHAYRAT EL KHASIB",
    },
    url: "",
    path: "/brands/5",

    info: [],
    products: [],
  },
  alAmeri: {
    id: 6,
    name: "AL Ameri Company For Trading",
    description:
      "Lorem ipsum dolor sit amet consectetur. Euismod eu morbi at duis. Congue amet",
    logo: {
      src: alAmeri,
      alt: "AL Ameri Company For Trading",
    },
    url: "",
    about:
      "Welcome to Fusteka Ice Cream! We are proud to have been serving our delicious ice cream since 2001. Our passion for creating the highest quality ice cream using only the freshest ingredients has been guiding principle from the beginning Our passion for creating the highest quality ice cream using only the freshest ingredients has been our.",
    path: "/brands/6",
    brandHeaderImage: {
      src: alameriHeaderImage,
      alt: "AL Ameri Company For Trading",
    },
    info: [
      {
        image: {
          src: pipeline,
          alt: "Pipeline cleaning",
        },
        comment:
          "This company is interested in Pipeline cleaning , Tanks Construction and Rehabilitation.",
      },
      {
        image: {
          src: transportation,
          alt: "Heavy Static Equipment",
        },
        comment: "Transportation and Installation of Heavy Static Equipment",
      },
      {
        image: { src: steelStructure, alt: "Structure Erection" },
        comment: "Steel Structure Erection & Fabrication, HVAC System , etc.",
      },
      {
        image: { src: trucks, alt: "trucks" },
        comment:
          "This company has the worth in terms of trucks, equipments and crew to do and apply any project.",
      },
      {
        image: { src: civilConstruction, alt: "civil construction" },
        comment:
          "Also interested in civil construction (Asphalt Plants, Batching Plant, Excavation , Backfilling , Foundation for Heavy Constructions ,building Construction, Fabricated Buildings )",
      },
      {
        image: { src: waterSystem, alt: "Water System" },
        comment: "Also Water System, Tank Construction, Bridges Construction.",
      },
    ],
    products: [],
  },
  fusteka: {
    id: 7,
    name: "FUSTEKA ICE CREAM",
    description:
      "Lorem ipsum dolor sit amet consectetur. Euismod eu morbi at duis. Congue amet",
    logo: {
      src: fusteka,
      alt: "FUSTEKA ICE CREAM",
    },
    brandHeaderImage: {
      src: fustekaBrandHeader,
      alt: "fusteka",
    },
    about:
      "Welcome to Fusteka Ice Cream! We are proud to have been serving our delicious ice cream since 2001. Our passion for creating the highest quality ice cream using only the freshest ingredients has been guiding principle from the beginning Our passion for creating the highest quality ice cream using only the freshest ingredients has been our.",
    url: "",
    path: "/brands/7",

    info: [
      {
        comment: "3 Factories",
        image: JekorInfo1,
      },
      {
        comment: "985 Employee",
        image: JekorInfo2,
      },
      {
        comment: "70 Items",
        image: JekorInfo3,
      },
      {
        comment: "39 Distributor in Iraq",
        image: JekorInfo4,
      },
      {
        comment: "21 Production line",
        image: JekorInfo5,
      },
      {
        comment: "100 million L / year",
        image: JekorInfo6,
      },
      {
        comment: "+10 New references/year",
        image: JekorInfo7,
      },
    ],
    products: [
      {
        id: 1,
        img: {
          src: fromStickToCups,
          alt: "from sticks to cups",
        },
        name: "From Sticks To Cups",
      },
      {
        id: 2,
        img: {
          src: fromConesToSandwiches,
          alt: "from cones to sandwiches",
        },
        name: "From Cones To Sandwiches",
      },
      {
        id: 3,
        img: {
          src: toyIcecream,
          alt: "From frozen pastry to “toy & ice cream” concepts",
        },
        name: "From frozen pastry to “toy & ice cream” concepts",
      },
      {
        id: 4,
        img: {
          src: withFruits,
          alt: "Ice cream with fruits",
        },
        name: "Ice cream with fruits",
      },
      {
        id: 5,
        img: {
          src: noveltiesInGlass,
          alt: "Novelties in glass",
        },
        name: "Novelties in glass",
      },
    ],
  },
};
// const brands = [
//    {
//       id: '1',
//       name: 'JEKOR FROZEN FOOD',
//       description: 'Lorem ipsum dolor sit amet consectetur. Euismod eu morbi at duis. Congue amet',
//       src: jekor,
//       alt: 'JEKOR FROZEN FOOD',
//       path: '/jekor',
//    },
//    {
//       id: '2',
//       name: 'AL ERWA',
//       description: 'Lorem ipsum dolor sit amet consectetur. Euismod eu morbi at duis. Congue amet',
//       src: alErwa,
//       alt: 'AL ERWA',
//       path: '/al-erwa',
//    },
//    {
//       id: '3',
//       name: 'VIRTUAL WORLD GROUP',
//       description: 'Lorem ipsum dolor sit amet consectetur. Euismod eu morbi at duis. Congue amet',
//       src: virtualWorldGroup,
//       alt: 'VIRTUAL WORLD GROUP',
//       path: '/virtual-world-group',
//    },
//    {
//       id: '4',
//       name: 'BASRAH CENTER',
//       description: 'Lorem ipsum dolor sit amet consectetur. Euismod eu morbi at duis. Congue amet',
//       src: basrahCenter,
//       alt: 'BASRAH CENTER',
//       path: '/basrah',
//    },
//    {
//       id: '5',
//       name: 'KHAYRAT EL KHASIB',
//       description: 'Lorem ipsum dolor sit amet consectetur. Euismod eu morbi at duis. Congue amet',
//       src: khayratAlKhaseb,
//       alt: 'KHAYRAT EL KHASIB',
//       path: '/khayrat-el-khasib',
//    },
//    {
//       id: '6',
//       name: 'alAmery',
//       description: 'Lorem ipsum dolor sit amet consectetur. Euismod eu morbi at duis. Congue amet',
//       src: alAmery,
//       alt: 'alAmery',
//       path: '/al-amery',
//    },
//    {
//       id: '7',
//       name: 'FUSTEKA ICE CREAM',
//       description: 'Lorem ipsum dolor sit amet consectetur. Euismod eu morbi at duis. Congue amet',
//       src: fusteka,
//       alt: 'FUSTEKA ICE CREAM',
//       path: '/fusteka',
//    },
//    {
//       id: '8',
//       name: 'AL ERWA',
//       description: 'Lorem ipsum dolor sit amet consectetur. Euismod eu morbi at duis. Congue amet',
//       src: alErwa,
//       alt: 'AL ERWA',
//       path: '/al-erwa',
//    },
// ];

const certifications = [
  { id: "1", src: certificate1, alt: "certificate1" },
  { id: "2", src: certificate2, alt: "certificate2" },
  { id: "3", src: certificate3, alt: "certificate3" },
  { id: "4", src: certificate1, alt: "certificate1" },
  { id: "5", src: certificate2, alt: "certificate2" },
  { id: "6", src: certificate3, alt: "certificate3" },
];

const contactInfo = [
  { id: "1", icon: phone, info: "6655", alt: "phone number" },
  { id: "2", icon: location, info: "Al Maamel, Basrah, Basra Governorate, Iraq", alt: "location" },
  { id: "3", icon: email, info: "contact@fusteka.com", alt: "email address" },
];

const socialMediaIcons = [
  { id: "1", icon: linkedIn, alt: "facebook", url: "" },
  { id: "2", icon: facebook, alt: "facebook", url: "" },
  { id: "3", icon: twitter, alt: "facebook", url: "" },
  { id: "4", icon: instagram, alt: "facebook", url: "" },
];

const members = [
  {
    id: 1,
    name: "Zeyad Mohamed",
    position: "Product manager",
    description:
      "Lorem ipsum dolor sit amet consectetur. Euismod eu morbi at duis. Congue amet",
    imgURL: member1,
    socialMedia: [
      {
        id: 1,
        platform: "Twitter",
        url: "",
        icon: memberTwitter,
      },
      {
        id: 2,
        platform: "Instagram",
        url: "",
        icon: memberInstagram,
      },
      {
        id: 3,
        platform: "LinkedIn",
        url: "",
        icon: memberLinkedIn,
      },
    ],
  },
  {
    id: 2,
    name: "Zeyad Mohamed",
    position: "Product manager",
    description:
      "Lorem ipsum dolor sit amet consectetur. Euismod eu morbi at duis. Congue amet",
    imgURL: member2,
    socialMedia: [
      {
        id: 1,
        platform: "Twitter",
        url: "",
        icon: memberTwitter,
      },
      {
        id: 2,
        platform: "Instagram",
        url: "",
        icon: memberInstagram,
      },
      {
        id: 3,
        platform: "LinkedIn",
        url: "",
        icon: memberLinkedIn,
      },
    ],
  },
  {
    id: 3,
    name: "Zeyad Mohamed",
    position: "Product manager",
    description:
      "Lorem ipsum dolor sit amet consectetur. Euismod eu morbi at duis. Congue amet",
    imgURL: member3,
    socialMedia: [
      {
        id: 1,
        platform: "Twitter",
        url: "",
        icon: memberTwitter,
      },
      {
        id: 2,
        platform: "Instagram",
        url: "",
        icon: memberInstagram,
      },
      {
        id: 3,
        platform: "LinkedIn",
        url: "",
        icon: memberLinkedIn,
      },
    ],
  },
  {
    id: 4,
    name: "Zeyad Mohamed",
    position: "Product manager",
    description:
      "Lorem ipsum dolor sit amet consectetur. Euismod eu morbi at duis. Congue amet",
    imgURL: member4,
    socialMedia: [
      {
        id: 1,
        platform: "Twitter",
        url: "",
        icon: memberTwitter,
      },
      {
        id: 2,
        platform: "Instagram",
        url: "",
        icon: memberInstagram,
      },
      {
        id: 3,
        platform: "LinkedIn",
        url: "",
        icon: memberLinkedIn,
      },
    ],
  },
];

const ourValues = [
  {
    id: 1,
    imgURL: value1,
    alt: "Integrity",
    title: "Integrity",
    body: "We always try to do the right thing. We are honest and straightforward with each other.",
  },
  {
    id: 2,
    imgURL: value2,
    title: "Leadership",
    alt: "Leadership",
    body: "We are all leaders in our area of responsibility, with a deep commitment to delivering leadership results. We have a clear vision of where we are going.",
  },
  {
    id: 3,
    imgURL: value3,
    title: "Ownership",
    alt: "Ownership",
    body: "We accept personal accountability to meet our business needs, ...Show more",
  },
  {
    id: 4,
    imgURL: value1,
    alt: "Integrity",
    title: "Integrity",
    body: "We always try to do the right thing. We are honest and straightforward with each other.",
  },
  {
    id: 5,
    imgURL: value2,
    title: "Leadership",
    alt: "Leadership",
    body: "We are all leaders in our area of responsibility, with a deep commitment to delivering leadership results. We have a clear vision of where we are going.",
  },
  {
    id: 6,
    imgURL: value3,
    title: "Ownership",
    alt: "Ownership",
    body: "We accept personal accountability to meet our business needs, ...Show more",
  },
];
const milestones = [
  {
    id: 1,
    year: 2000,
    background: yearbackground1,
    correspondingEvent:
      "Fusteka Group was established in 2000 in Basra city, Iraq.",
  },
  {
    id: 2,
    year: 2002,
    background: yearbackground2,
    correspondingEvent:
      "Fusteka Group was established in 2000 in Basra city, Iraq.",
  },
  {
    id: 3,
    year: 2008,
    background: yearbackground3,
    correspondingEvent:
      "Fusteka Group was established in 2000 in Basra city, Iraq.",
  },
  {
    id: 4,
    year: 2008,
    background: yearbackground4,
    correspondingEvent:
      "Fusteka Group was established in 2000 in Basra city, Iraq.",
  },
  {
    id: 5,
    year: 2008,
    background: yearbackground5,
    correspondingEvent:
      "Fusteka Group was established in 2000 in Basra city, Iraq.",
  },
  {
    id: 6,
    year: 2008,
    background: yearbackground6,
    correspondingEvent:
      "Fusteka Group was established in 2000 in Basra city, Iraq.",
  },
];

const openVacancies = [
  {
    id: "1",
    role: "Senior UX Designer",
    jobDescription:
      "Lorem ipsum dolor sit amet consectetur. Velit hendrerit amet mattis amet lorem mauris facilisi. Nisi vitae fermentum amet ut at augue odio.",
    responsibilities: [
      "Lorem ipsum dolor sit amet consectetur.",
      "Lorem ipsum dolor sit amet consectetur.",
      "Lorem ipsum dolor sit amet consectetur.",
    ],
    requirements: [
      "Lorem ipsum dolor sit amet consectetur.",
      "Lorem ipsum dolor sit amet consectetur.",
      "Lorem ipsum dolor sit amet consectetur.",
    ],
  },
  {
    id: "2",
    role: "Senior UX Designer",
    jobDescription:
      "Lorem ipsum dolor sit amet consectetur. Velit hendrerit amet mattis amet lorem mauris facilisi. Nisi vitae fermentum amet ut at augue odio.",
    responsibilities: [
      "Lorem ipsum dolor sit amet consectetur.",
      "Lorem ipsum dolor sit amet consectetur.",
      "Lorem ipsum dolor sit amet consectetur.",
    ],
    requirements: [
      "Lorem ipsum dolor sit amet consectetur.",
      "Lorem ipsum dolor sit amet consectetur.",
      "Lorem ipsum dolor sit amet consectetur.",
    ],
  },
  {
    id: "3",
    role: "Senior UX Designer",
    jobDescription:
      "Lorem ipsum dolor sit amet consectetur. Velit hendrerit amet mattis amet lorem mauris facilisi. Nisi vitae fermentum amet ut at augue odio.",
    responsibilities: [
      "Lorem ipsum dolor sit amet consectetur.",
      "Lorem ipsum dolor sit amet consectetur.",
      "Lorem ipsum dolor sit amet consectetur.",
    ],
    requirements: [
      "Lorem ipsum dolor sit amet consectetur.",
      "Lorem ipsum dolor sit amet consectetur.",
      "Lorem ipsum dolor sit amet consectetur.",
    ],
  },
  {
    id: "4",
    role: "Senior UX Designer",
    jobDescription:
      "Lorem ipsum dolor sit amet consectetur. Velit hendrerit amet mattis amet lorem mauris facilisi. Nisi vitae fermentum amet ut at augue odio.",
    responsibilities: [
      "Lorem ipsum dolor sit amet consectetur.",
      "Lorem ipsum dolor sit amet consectetur.",
      "Lorem ipsum dolor sit amet consectetur.",
    ],
    requirements: [
      "Lorem ipsum dolor sit amet consectetur.",
      "Lorem ipsum dolor sit amet consectetur.",
      "Lorem ipsum dolor sit amet consectetur.",
    ],
  },
  {
    id: "5",
    role: "Senior UX Designer",
    jobDescription:
      "Lorem ipsum dolor sit amet consectetur. Velit hendrerit amet mattis amet lorem mauris facilisi. Nisi vitae fermentum amet ut at augue odio.",
    responsibilities: [
      "Lorem ipsum dolor sit amet consectetur.",
      "Lorem ipsum dolor sit amet consectetur.",
      "Lorem ipsum dolor sit amet consectetur.",
    ],
    requirements: [
      "Lorem ipsum dolor sit amet consectetur.",
      "Lorem ipsum dolor sit amet consectetur.",
      "Lorem ipsum dolor sit amet consectetur.",
    ],
  },
];
const jekorCompanyInfo = [
  {
    comment: "3 Factories",
    image: JekorInfo1,
  },
  {
    comment: "985 Employee",
    image: JekorInfo2,
  },
  {
    comment: "70 Items",
    image: JekorInfo3,
  },
  {
    comment: "39 Distributor in Iraq",
    image: JekorInfo4,
  },
  {
    comment: "21 Production line",
    image: JekorInfo5,
  },
  {
    comment: "100 million L / year",
    image: JekorInfo6,
  },
  {
    comment: "+10 New references/year",
    image: JekorInfo7,
  },
];

const jekorProducts = [
  {
    id: 1,
    img: {
      src: meatItemsType,
      alt: "Meat Items Type",
    },
    name: "Meat Items Type",
  },
  {
    id: 2,
    img: {
      src: chickenItemsType,
      alt: "Chicken Items Type",
    },
    name: "Chicken Items Type",
  },
  {
    id: 3,
    img: {
      src: vegItemsType,
      alt: "Vegetables Items Type",
    },
    name: "Vegetables Items Type",
  },
  // {
  //   id: 4,
  //   img: {
  //     src: cheeseItemsType,
  //     alt: "Cheese Items Type",
  //   },
  //   name: "Cheese Items Type",
  // },
];

export {
  brands,
  certifications,
  contactInfo,
  socialMediaIcons,
  members,
  ourValues,
  milestones,
  openVacancies,
  jekorCompanyInfo,
  jekorProducts,
};
