import { Routes, Route } from "react-router-dom";

import HomePage from "pages/home/Home.Page.jsx";
import AboutPage from "pages/about/About.Page";
import Layout from "components/Organisms/layout";
import ContactPage from "pages/contact/Contact.Page";
import BrandsPage from "pages/brands/Brands.Page";
import CareersPage from "pages/careers/Careers.Page";
import Jekor from "pages/brands/BrandDetails.Page";
import Fusteka from "pages/brands/Fusteka.Page";
import VirtualWorldGroup from "pages/brands/VirtualWorldGroup.Page";
import Alameri from "pages/brands/Alameri.page";
import BrandsDetailsPage from "pages/brands/BrandDetails.Page";
import Newspage from "pages/News/News.page";
import NewsDetailspage from "pages/News/NewsDetails.page";

const AppRoute = () => {
  // this state is for update breadcrumb data for each page
  // const [layoutData, setLayoutData] = useState(null);
  return (
    <Routes>
      {/* admin layout */}
      <Route path="/" element={<Layout />}>
        <Route index element={<HomePage />} />
        <Route path="/About" element={<AboutPage  />} />
        <Route path="/Contact" element={<ContactPage />} />
        <Route path="/Careers" element={<CareersPage />} />

        <Route path="/Brands" element={<BrandsPage />} />
        <Route path="/Brands/:id" element={<BrandsDetailsPage />} />
        <Route path="/Jekor" element={<BrandsDetailsPage />} />
        <Route path="/Fusteka" element={<Fusteka />} />
        <Route path="/latest-news" element={<Newspage />} />
        <Route path="/news-details/:id" element={<NewsDetailspage />} />
        <Route path="/virtual-world-group" element={<VirtualWorldGroup />} />
        <Route path="/al-ameri" element={<Alameri />} />

        <Route element={<p>Not found</p>} />
      </Route>
    </Routes>
  );
};

export default AppRoute;
