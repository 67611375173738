import { Box, Button, Typography, Link, Stack } from "@mui/material";
import WrappingContainer from "components/Atoms/Containers/WrappingContainer";
import CustomFlexContainer from "components/Atoms/Containers/CustomFlexContainer";
import Logo from "components/Atoms/Cards/Brands/Logo";
import { useThemePalette } from "common/hooks/theme_palette";
import gradientBackground from "../../../assets/images/backgrounds/gradient-background.png";
import { useState } from "react";

export default function BrandHeader({ brandsDetailsData }) {
  const { image, about_us_data } = brandsDetailsData;
  const { orange, darkGray } = useThemePalette();
  const [lettersToShow, setLettersToShow] = useState(100);

  const handleReadMore = () => {
    if (lettersToShow < about_us_data.description.length) {
      setLettersToShow(about_us_data?.description?.length);
    }
  };
  return (
    <Box sx={{ mb: { md: 50, xs: 30 } }}>
      <Box
        sx={{
          boxSizing: "border-box",
          position: "relative",
        }}
      >
        <Stack
          sx={{
            display: "flex",
            pb: { lg: "213px", md: "180px", sm: "140px", xs: "68px" },
            position: "relative",
          }}
        >
          <picture
            style={{
              display: "flex",
              height: "100%",
              width: "100%",
              alignItems: "center",
            }}
          >
            {image?.slice(0, 3)?.map((img) => (
              <source
                key={img?.size}
                media={`(min-width:${img?.size}px)`}
                srcSet={img?.image_url}
              />
            ))}
            <Box
              component={"img"}
              src={image?.filter((img) => img?.size == 1920)[0]?.image_url}
              width="100%"
              loading="lazy"
            />
          </picture>
          <Box
            sx={{
              position: "absolute",
              bottom: "15%",
              right: "50%",
              transform: "translate(50% , -50%)",
              zIndex: 999,
            }}
          >
            <Link target="_blank" href={brandsDetailsData?.link}>
              <Button
                sx={{
                  fontSize: "23px",
                  background:
                    "linear-gradient(180deg, #FFF5DC 0%, #FFD04D 37.5%, #FFCA2A 100%)",
                  boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
                  borderRadius: "70px",
                  px: 20,
                  mb: 10.7,
                  color: darkGray,
                  fontWeight: 500,
                  display: { md: "block", xs: "none" },
                }}
              >
                Visit Website
              </Button>
            </Link>
          </Box>
        </Stack>

        <Stack
          justifyContent="center"
          sx={{
            position: "absolute",
            bottom: 0,
            // height: '213px',
            width: "100%",
            zIndex: 9,
          }}
        >
          <Stack
            direction={"row"}
            alignItems="center"
            justifyContent="space-between"
            sx={{
              display: "flex",
              flexWrap: "wrap",
              alignItems: "center",
              gap: 10,
            }}
          >
            <Box sx={{ pl: { lg: 37 } }} className="brand-logo">
              <Logo
                src={brandsDetailsData?.logo}
                alt={"brand logo"}
                borderRadius="50%"
                backgroundColor="white"
                sx={{
                  imgWidth: {
                    lg: "100% !important",
                    md: "90%",
                    sm: "70%",
                    xs: "90%",
                  },
                  width: { lg: "300px", md: "20vw", sm: "170px", xs: "100px" },
                  height: { lg: "300px", md: "20vw", sm: "170px", xs: "100px" },
                  p: { md: 15, xs: 3 },
                  position: "relative",
                  top: {
                    lg: "67px !important",
                    sm: "44px !important",
                    xs: "42px",
                  },
                  left: { lg: "5%", xs: "11%" },
                  zIndex: 1,
                }}
              />
            </Box>
            <Stack
              sx={{
                width: "70%",
              }}
            >
              <Stack
                justifyContent="center"
                sx={{
                  position: "absolute",
                  right: { xs: 0 },
                  bottom: 0,
                  height: { lg: "213px", md: "180px", sm: "140px", xs: "68px" },
                  zIndex: 0,
                }}
              >
                <Box
                  component="picture"
                  sx={{
                    height: {
                      lg: "213px",
                      md: "180px",
                      sm: "140px",
                      xs: "68px",
                    },
                  }}
                >
                  {brandsDetailsData?.years_image?.slice(0, 3)?.map((img) => (
                    <Box
                      component="source"
                      // style={{ width: "100%", bgcolor:'blue',height:'100%' }}
                      key={img?.size}
                      media={`(min-width:${img?.size}px)`}
                      srcSet={img?.image_url}
                      width="fit-content"
                      sx={{ height: "100%" }}
                    />
                  ))}
                  <Box
                    component="img"
                    width="100%"
                    src={
                      brandsDetailsData?.years_image?.filter(
                        (img) => img?.size == 1920
                      )[0]?.image_url
                    }
                    sx={{
                      height: "100%",
                    }}
                    alt=""
                  />
                </Box>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Box>
      <Box
        sx={{
          pt: "6%",
          backgroundImage: `url(${gradientBackground})`,
          backgroundPosition: "center",
          backgroundSize: "100% 100%",
          backgroundRepeat: "no-repeat",
          pb: { md: 50, xs: 30 },
        }}
      >
        <WrappingContainer sx={{ color: "white" }}>
          <CustomFlexContainer col gap={15}>
            <Stack
              justifyContent="space-between"
              direction={{ md: "row" }}
              sx={{
                //  height: { lg: "500px" },
                width: "100%",
              }}
            >
              <Stack
                alignItems="start"
                sx={{
                  width: { xs: "35%", lg: "40%", md: "40%", xs: "100%" },
                  // height: "100%",
                  pt: 15,
                }}
                justifyContent={{ md: "space-between", xs: "center" }}
              >
                <Box sx={{ width: "100%" }}>
                  <Typography
                    sx={{
                      fontSize: { md: "52px", sm: "30px", xs: "19px" },
                      textAlign: { md: "start", xs: "center" },
                      fontWeight: 900,
                      fontFamily: "inter",
                      color: orange,
                      mb: "32px",
                      mt: 30,
                      letterSpacing: { md: "auto", xs: "4px" },
                    }}
                  >
                    About Us ...
                  </Typography>
                  <Stack direction="row" className="details-description">
                    <Typography
                      component="span"
                      sx={{
                        fontSize: { lg: "33px", sm: "18px", xs: "16px" },
                        fontFamily: "Inter",
                        lineHeight: { md: "40px" },
                        letterSpacing: "3px",
                        mb: { lg: 0, md: 30, xs: "16px" },
                        textAlign: { md: "start", xs: "center" },
                      }}
                    />
                    {/* <Typography
                      component="span"
                      sx={{
                        fontSize: { lg: "33px", sm: "18px", xs: "16px" },
                        fontFamily: "Inter",
                        lineHeight: { md: "40px" },
                        letterSpacing: "3px",
                        mb: { lg: 0, md: 30, xs: "16px" },
                        textAlign: { md: "start", xs: "center" },
                      }}
                      dangerouslySetInnerHTML={{
                        __html:
                          about_us_data?.description?.length > lettersToShow
                            ? `${about_us_data?.description?.slice(
                                0,
                                lettersToShow
                              )}`
                            : about_us_data?.description,
                      }}
                    /> */}
                    <Typography
                      component="span"
                      sx={{
                        fontSize: { lg: "33px", sm: "18px", xs: "16px" },
                        fontFamily: "Inter",
                        lineHeight: { md: "40px" },
                        letterSpacing: "3px",
                        mb: { lg: 0, md: 30, xs: "16px" },
                        textAlign: { md: "start", xs: "center" },
                      }}
                      dangerouslySetInnerHTML={{
                        __html: about_us_data?.description,
                      }}
                    />
                    {/* {about_us_data?.description?.length > lettersToShow
                        ? `${about_us_data?.description?.slice(
                            0,
                            lettersToShow
                          )}`
                        : about_us_data?.description} */}
                    {/* <Typography
                        component="span"
                        sx={{
                          fontSize: "35px",
                          fontWeight: "700",
                          cursor: "pointer",
                        }}
                        onClick={handleReadMore}
                      >
                        {about_us_data?.description?.length > lettersToShow &&
                          ".. Read More"}
                      </Typography> */}
                    {/* </Typography> */}
                  </Stack>
                </Box>
              </Stack>
              <Stack
                justifyContent="center"
                alignItems="center"
                sx={{
                  width: { md: "58%" },
                  // height: { lg: 'fit-content' }
                }}
              >
                {/* <Box
                           src={images.aboutBrands2}
                           component='img'
                           alt='about fustuka group'
                           width={'100%'}
                        /> */}
                <picture>
                  {/* {about_us_data?.image?.slice(0, 3)?.map((img) => (
                              <source
                                 key={img?.size}
                                 media={`(min-width:${img?.size}px)`}
                                 srcSet={img?.image_url}
                                 width={'40%'}
                              />
                           ))} */}
                  <Box
                    component="img"
                    src={
                      about_us_data?.image?.filter(
                        (img) => img?.size == 1920
                      )[0]?.image_url
                    }
                    alt=""
                    width={"100%"}
                  />
                </picture>
                <Link target="_blank" href={brandsDetailsData?.link}>
                  <Button
                    sx={{
                      fontSize: "16px",
                      background:
                        "linear-gradient(180deg, #FFF5DC 0%, #FFD04D 37.5%, #FFCA2A 100%)",
                      boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
                      borderRadius: "70px",
                      px: 20,
                      py: 5,
                      mt: 15,
                      color: darkGray,
                      fontWeight: 500,
                      display: { md: "none", xs: "block" },
                      textDecoration: "none !important",
                    }}
                  >
                    Visit Website
                  </Button>
                </Link>
              </Stack>
            </Stack>
          </CustomFlexContainer>
        </WrappingContainer>
      </Box>
    </Box>
  );
}
