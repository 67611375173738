import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { aboutPageDataRequest } from "services/modules/about";
import { homePageDataRequest } from "services/modules/home";
import AboutUs from "templates/about/About.Template";

export default function About() {
  const dispatch = useDispatch();

  const { aboutPageData } = useSelector((state) => state.aboutPage);
  const { homePageData } = useSelector((state) => state.homePage);

  useEffect(() => {
    const requestData = {
      body: { jsonrpc: "2.0", params: {} },
    };
    dispatch(aboutPageDataRequest({ ...requestData }));
    dispatch(homePageDataRequest({ ...requestData }));
  }, []);

  return (
    <AboutUs
      aboutPageData={aboutPageData}
      homePageData={homePageData}
    />
  );
}
