import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { newsPageDataRequest } from "services/modules/news";
import NewsTemplate from "templates/news/News.Template";

export default function Newspage() {
  const dispatch = useDispatch();

  const { newsPageData } = useSelector((state) => state?.newsPage);
  useEffect(() => {
    const requestData = {
      body: { jsonrpc: "2.0", params: {} },
    };
    dispatch(newsPageDataRequest({ ...requestData }));
  }, []);

  return (
    <>
      <NewsTemplate newsPageData={newsPageData && newsPageData} />
    </>
  );
}
