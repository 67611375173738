import { Outlet } from "react-router-dom";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import { contactInfo, socialMediaIcons } from "data";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef } from "react";
import { brandsPageDataRequest } from "services/modules/brands";
import { Box } from "@mui/material";

export default function Layout({ layoutData }) {
  const dispatch = useDispatch();
  const { brandsPageData } = useSelector((state) => state.brandsPage);
  const navbarRef = useRef();

  useEffect(() => {
    const requestData = {
      body: { jsonrpc: "2.0", params: {} },
    };
    dispatch(brandsPageDataRequest({ ...requestData }));
  }, []);
  return (
    <>
      <Navbar brandsPageData={brandsPageData} navbarRef={navbarRef} />
      <Box sx={{ mt: `${navbarRef?.current?.clientHeight}px` }}>
        <Outlet />
      </Box>
      <Footer contactInfo={contactInfo} socialMediaIcons={socialMediaIcons} />
    </>
  );
}
