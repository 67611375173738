import { Box, Grid, Typography } from '@mui/material';
import CustomFlexContainer from 'components/Atoms/Containers/CustomFlexContainer';
import WrappingContainer from 'components/Atoms/Containers/WrappingContainer';

export default function AboutCompany({ info, body }) {
   return (
      <Box>
         <WrappingContainer sx={{ py: 40, textAlign: 'center' }}>
            <CustomFlexContainer col gap={15}>
               <Typography
                  sx={{
                     m: 0,
                     color: '#202249',
                     fontWeight: '900',
                     fontSize: '58px',
                     letterSpacing: '4px',
                  }}
               >
                  ABOUT COMPANY
               </Typography>
               <Typography
                  variant="body1"
                  sx={{
                     fontSize: '23px',
                     fontWeight: '400',
                     lineHeight: '42px',
                     width: '95%',
                     color: '#6B6B6B',
                  }}
               >
                  {body}
               </Typography>
               <CustomFlexContainer
                  gap={15}
                  sx={{
                     minWidth: '65%',
                     maxWidth: '90%',
                     flexWrap: 'wrap',
                     justifyContent: 'center',
                  }}
               >
                  <Grid
                     container
                     sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        gap: { xs: 10, md: 35 },
                     }}
                  >
                     {info.map((info, i) => (
                        <Grid item key={info.id} xl={5} md={5}>
                           <img
                              src={info.image.src}
                              alt={info.image.alt}
                              width="100%"
                              loading="lazy"
                           />

                           <Typography
                              sx={{ fontSize: '22px', color: '#6B6B6B', fontWeight: '400', lineHeight:'35px' }}
                           >
                              <span style={{ fontWeight: '600' }}>{info.name}</span>
                              <span>{info.comment}</span>
                           </Typography>
                        </Grid>
                     ))}
                  </Grid>
               </CustomFlexContainer>
            </CustomFlexContainer>
         </WrappingContainer>
      </Box>
   );
}
