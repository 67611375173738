import { Box } from '@mui/material';

import SectionHeader from 'components/Atoms/Text/SectionHeader';
import CustomFlexContainer from 'components/Atoms/Containers/CustomFlexContainer';
import WrappingContainer from 'components/Atoms/Containers/WrappingContainer';
import DisplayOpenVacancies from 'components/Molecules/Careers/DisplayOpenVacancies';
import ApplicationForm from 'components/Molecules/Careers/ApplicationForm';

export default function JoinUs({
   jobs,
   educationDegreesData,
   jobPositionsData
}) {
   return (
      <Box
         sx={{ 
            background: 'linear-gradient(66deg, rgba(21,17,39,1) 68%, rgba(40,86,166,1) 100%)',
            py: 40,
         }}
      >
         <WrappingContainer>
            <CustomFlexContainer col gap={{ md: 30, sm: 15, xs: 10 }}>
               <SectionHeader
                  sx={{
                     fontSize: { md: '50px', sm: '35px', xs: '19px' },
                  }}
               >
                  BE PART OF OUR MISSION
               </SectionHeader>
               {/* open vacancies */}
               <DisplayOpenVacancies jobs={jobs} />
               {/* send your cv */}
               <ApplicationForm
                  educationDegreesData={educationDegreesData}
                  jobPositionsData={jobPositionsData}
               />
            </CustomFlexContainer>
         </WrappingContainer>
      </Box>
   );
}
