import OpenVacancy from "./OpenVacancy";
import { Accordion, Typography, AccordionSummary, Box } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CustomFlexContainer from "components/Atoms/Containers/CustomFlexContainer";
import { useState, useTransition, useEffect } from "react";
import Animation from "components/Atoms/SharedComponent/Animation";
import { useRef } from "react";

export default function DisplayOpenVacancies({ jobs }) {
  const [selectedId, setSelectedId] = useState(null);
  const [isPending, startTransation] = useTransition();
  const targetElementRef = useRef(null);

  const handleChange = (id) => {
    startTransation(() => {
      setSelectedId(id === selectedId ? null : id);
    });
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Typography
        fontSize={{ md: "28px", sm: "19px", xs: "15px" }}
        color="secondary"
        sx={{ marginBottom: 10, ml: "10px" }}
      >
        Open Vacancies
      </Typography>
      <Animation
        targetElementRef={targetElementRef}
        className={"fade-up-animation"}
      />

      <Box ref={targetElementRef} className="fade-up">
        <CustomFlexContainer
          col
          gap={15}
          sx={{
            backgroundColor: "#1B2853",
            p: 9,
            borderRadius: "8px",
            alignItems: "stretch",
          }}
        >
          {jobs?.map((vacancy, index) => {
            const isOpen = selectedId === vacancy?.id;

            return (
              <Accordion
                key={index}
                expanded={isOpen}
                id={`panel${index}bh-header`}
                aria-controls={`panel${index}bh-content`}
                onChange={() => handleChange(vacancy?.id)}
                sx={{
                  background: "rgba(217, 217, 217, 0.10)",
                  color: "white",
                  p: 3,
                  borderRadius: "6px",
                }}
              >
                <AccordionSummary
                  expandIcon={
                    <ExpandMoreIcon fontSize="large" sx={{ color: "white" }} />
                  }
                  aria-controls={`panel${index}bh-content`}
                  id={`panel${index}bh-header`}
                >
                  <Typography
                    sx={{
                      flexShrink: 0,
                      fontSize: { md: "22px", sm: "16px", xs: "13px" },
                    }}
                  >
                    {vacancy?.title}
                  </Typography>
                </AccordionSummary>
                {isOpen && <OpenVacancy key={selectedId} vacancy={vacancy} />}
              </Accordion>
            );
          })}
        </CustomFlexContainer>
      </Box>
    </Box>
  );
}
