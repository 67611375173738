/* eslint-disable array-callback-return */
import Types from "./Types";

const INIT_STATE = {
  contactUsPageData: {},
  isLoading: {
    type: "",
    loading: false,
  },
};

export default function contactUsPageReducer(state = INIT_STATE, action) {
  const { type, payload } = action;
  switch (type) {
    case Types.CONTACT_US_PAGE_DATA_LOADING: {
      return {
        ...state,
        isLoading: payload,
      };
    }
    case Types.CONTACT_US_PAGE_DATA_SUCCESS: {
      return {
        ...state,
        contactUsPageData: payload?.result?.data,
      };
    }
    default: {
      return state;
    }
  }
}
